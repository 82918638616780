import React from "react";
import {
  ProcedureEditIcon,
  BackArrowIcon,
  SensorIcon,
  ArrowIcon,
  LogisticIcon,
  SquareBoxSelected,
  SquareBoxNotSelected,
  // ProcedureUserIcon,
  UserIcon,
} from "../../Assets/assets";

export const ProcedureCard = (props) => {
  return (
    <div
      data-cy={`${props.data.documentId}-card`}
      className={`display-flex flex-justify-content-space-between flex-align-items-center padding-top-default padding-bottom-default  padding-right-default ${
        props.selected
          ? " font-color-primary border-left-primary-6px padding-left-default"
          : "padding-left-large"
      }`}
    >
      <div
        style={{ width: "90%" }}
        className="font-size-default  text-capitalize white-space-no-wrap text-overflow-ellipsis"
      >
        {props.data.title}
      </div>
      <div className="display-flex flex-align-items-center">
        {props.data.procedureType === "employee" && (
          <div
            data-cy={`${props.data.documentId}-assign`}
            onClick={props.assignEmployeeOnClick}
            className="margin-left-larger cursor-pointer"
          >
            <UserIcon
              color={
                props.selected && props.activeState !== "edit"
                  ? "#0044cc"
                  : "white"
              }
            />
          </div>
        )}
        {props.data.procedureType === "sensor" && (
          <div
            data-cy={`${props.data.documentId}-assign`}
            onClick={props.assignSensorOnClick}
            className="margin-left-larger cursor-pointer"
          >
            <SensorIcon
              color={
                props.selected && props.activeState !== "edit"
                  ? "#0044cc"
                  : "white"
              }
            />
          </div>
        )}
        {props.data.procedureType === "logistics" && (
          <div
            data-cy={`${props.data.documentId}-assign`}
            onClick={props.assignLogisticOnClick}
            className="margin-left-larger cursor-pointer"
          >
            <LogisticIcon
              color={
                props.selected && props.activeState !== "edit"
                  ? "#0044cc"
                  : "white"
              }
            />
          </div>
        )}
        <div
          data-cy={`${props.data.documentId}-edit`}
          onClick={() => {
            props.editOnClick(props.data.documentId);
          }}
          className="margin-left-larger cursor-pointer"
        >
          <ProcedureEditIcon
            color={
              props.selected && props.activeState === "edit"
                ? "#0044cc"
                : "white"
            }
          />
        </div>
      </div>
    </div>
  );
};

export const HeaderCard = (props) => {
  return (
    <div className="display-flex flex-align-items-center">
      <div
        onClick={props.backArrowOnClick}
        className="padding-right-medium cursor-pointer"
      >
        <BackArrowIcon color={"white"} />
      </div>
      <div>
        <div className=" font-size-default">{props.title}</div>
        <div className="font-size-medium">{props.description}</div>
      </div>
    </div>
  );
};

export const ProcessCard = (props) => {
  return (
    <div className="display-flex flex-justify-content-center">
      <div
        className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-width padding-right-default"
        style={{
          maxWidth: "412px",
        }}
      >
        <div className="text-uppercase  font-size-default text-overflow-ellipsis inherit-parent-width ">
          {props.data.title}
        </div>
        <div className="text-capitalize padding-top-default font-size-medium text-overflow-ellipsis inherit-parent-width">
          {props.data.description}
        </div>
      </div>
      <div
        data-cy={props["data-cy"]}
        className="cursor-pointer"
        onClick={props.editOnclick}
      >
        <ProcedureEditIcon height={16} color={"white"} />
      </div>
    </div>
  );
};

export const EmployeeCard = (props) => {
  return (
    <div
      data-cy={props["data-cy"]}
      onClick={props.cardOnclick}
      className="display-flex cursor-pointer flex-align-items-center flex-justify-content-space-between inherit-parent-width"
    >
      <div className=" ">
        <div className="text-capitalize  font-size-default text-overflow-ellipsis ">
          {props.data?.name}
        </div>
        <div className="text-capitalize padding-top-small font-size-medium">
          {props.data?.phoneNumber}
        </div>
      </div>

      <div className="">
        <ArrowIcon height={16} color={"white"} />
      </div>
    </div>
  );
};

export const SensorCard = (props) => {
  return (
    <div
      data-cy={props["data-cy"]}
      onClick={props.cardOnclick}
      className={`display-flex cursor-pointer padding-left-default padding-right-default padding-top-smaller flex-justify-content-center flex-align-items-center ${
        props.selected ? "selected-background-color border-radius-default" : ""
      }`}
    >
      <div className="padding-right-large flex-justify-content-center">
        <SensorIcon
          height={22}
          width={20}
          color={
            props.selected && props.activeState !== "edit" ? "#002f44" : "white"
          }
        />
      </div>
      <div className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-width">
        <div className="text-uppercase  font-size-default text-overflow-ellipsis width-200px">
          {props.data?.name}
        </div>
      </div>
      <div className="margin-left-large">
        <ArrowIcon
          height={16}
          color={
            props.selected && props.activeState !== "edit" ? "#002f44" : "white"
          }
        />
      </div>
    </div>
  );
};

export const LocationCard = (props) => {
  return (
    <div
      data-cy={props["data-cy"]}
      onClick={props.cardOnclick}
      className="display-flex cursor-pointer flex-justify-content-center flex-align-items-center"
    >
      <div className="display-flex flex-direction-column flex-justify-content-space-between inherit-parent-width">
        <div className=" one-line-ellipsis font-size-default">
          {props.data?.location}
        </div>
        <div className="text-capitalize padding-top-small font-size-small">
          PCT : {props.data?.PCTNo} , Ward: {props.data.ward} , Zone:{" "}
          {props.data.zone}
        </div>
      </div>
      <div className="">
        {props.selected ? (
          <SquareBoxSelected color="#002f44" />
        ) : (
          <SquareBoxNotSelected color={"white"} />
        )}
      </div>
    </div>
  );
};
