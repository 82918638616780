import { ArcElement, CategoryScale, Legend, Tooltip } from "chart.js";
import Chart from "chart.js/auto";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import ComplaintsPreview from "../../Components/ComplaintsPreview/ComplaintsPreview";
import EmployeesPreview from "../../Components/EmployeesPreview/EmployeesPreview";
import EmployeeTable from "../../Components/EmployeeTable/EmployeeTable";
import { complaintsCategory } from "../../Components/Filter/constants";
import Filter from "../../Components/Filter/Filter";
import LocationsPreview from "../../Components/LocationsPreview/LocationsPreview";
import LocationTable from "../../Components/LocationTable/LocationTable";
import Modal from "../../Components/Modal/Modal";
import ProcedureHistoryTable from "../../Components/ProcedureHistoryTable/ProcedureHistoryTable";
import ProceduresPreview from "../../Components/ProceduresPreview/ProceduresPreview";
import {
  complaintTakeover,
  fixComplaints,
  getTotalComplaints,
  verifyCompliant
} from "../../Redux/complaints/action";
import { getProceduresAssets } from "../../Redux/procedures/action";
import {
  createPicture,
  editPicture,
  retryPictureUploadToDevices
} from "../../Redux/surveillance/action";
import {
  setSelectedTable,
  setSelectedTableData,
  setShowModal,
  setTableFilter
} from "../../Redux/table/action";
import useMap from "../../Services/useMap";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  complaintsTableField,
  complaintsTableHeadings,
  dateAndTimeConverter,
  getLocationList,
  getUniqueDataFromObj,
  isDigit,
  Regex,
  rippleEffect,
  tableTitles,
  useDeepEqual
} from "../../Utils/constants";
import "./Home.css";
import { LocationIcon, RefreshIcon } from "../../Assets/assets";
import SearchFallBackIcon from "../../Assets/SearchFallBackIcon.svg";
import ComplaintSummary from "../../Components/ComplaintSummary/ComplaintSummary";
import EmployeeCreate from "../../Components/EmployeeCreate/EmployeeCreate";
import LocationSummary from "../../Components/LocationSummary/LocationSummary";
import Table from "../../Components/Table/Table";
import WorkForceSummary from "../../Components/WorkForceSummary/WorkForceSummary";
import {
  addRolesToSelectedEmployee,
  createEmployee,
  getRolesData
} from "../../Redux/employees/action";
import { getComplaintSummary } from "../../Redux/locations/action";
import { lastUpdatedAt } from "../../Redux/track/action";

Chart.register(CategoryScale, ArcElement, Tooltip, Legend);

function Home(props) {
  const mapContainer = useRef(null);
  const [mapContainerRendered, setMapContainerRendered] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const scrollToElement = (documentId) => {
    const element = document?.getElementById(documentId);
    element?.classList.add("add-blink-effect");
    element?.setAttribute("style", "background-color:#1a3e59");

    element?.scrollIntoView({
      block: "center",
      inline: "center",
      behavior: "smooth"
    });

    setTimeout(() => {
      element?.removeAttribute("style");
    }, 1500);
  };

  const [drawRoutesInMap, clearRoutes] = useMap({
    locations: props.hits,
    container: mapContainer,
    mapContainerRendered: mapContainerRendered,
    isAuth: props.isAuth,
    markerOnclick: (objectId) => {
      setSelectedTable("LOCATIONS");
      props.setActiveSwitch("TABLE");
      scrollToElement(objectId);
    }
  });

  useEffect(() => {
    if (
      !props.tracks.loading &&
      props.table.workForce.filter.date !== null &&
      isValidObject(props.tracks.data) &&
      props.table.selectedTable === tableTitles.workForce &&
      props.claims?.beta
    ) {
      drawRoutesInMap(props.tracks.data, props.employees.data);
    }

    if (props.tracks.loading && !isValidObject(props.tracks.data)) {
      clearRoutes();
    }

    if (
      props.table.selectedTable !== tableTitles.workForce ||
      !props.claims?.beta
    ) {
      clearRoutes();
    }

    // eslint-disable-next-line
  }, [
    props.tracks.loading,
    props.table.workForce.filter.date,
    props.table.selectedTable
  ]);

  // useEffect(() => {
  //   console.log("MOUNTED");
  // }, []);

  useEffect(() => {
    if (props.activeSwitch) {
      props.setSelectedTableData("COMPLAINTS", null);
      props.setSelectedTableData("PROCEDURES", null);
      props.setSelectedTableData("LOCATIONS", null);
      props.setSelectedTableData("WORKFORCE", null);
    }
    // eslint-disable-next-line
  }, [props.activeSwitch]);

  useEffect(() => {
    setMapContainerRendered(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.table.complaints?.selectedComplaint) {
      props.getComplaintsAssets(props.table.complaints?.selectedComplaint);
    }
    // eslint-disable-next-line
  }, [props.complaints.data]);

  // console.log("rendered");
  useDeepEqual(props);

  useEffect(() => {
    if (props.isAuth && props.auth.data.uid) {
      props.getComplaintSummary();
      props.getTotalComplaints(props.hits);
      props.getRolesData();
    }
    // eslint-disable-next-line
  }, [
    props.table.showModal?.summary,
    props.enableFilter,
    props.table.complaints.filter
  ]);

  const getRemainingHeight = () => {
    switch (true) {
      case props.isFilterApplied:
        return `${
          props.activeSwitch === "MAP"
            ? "remaining-body-height-from-header-filter-map"
            : "remaining-body-height-from-header-filter"
        }`;

      default:
        return `${
          props.activeSwitch === "MAP"
            ? "remaining-body-height-from-header-map"
            : "remaining-body-height-from-header"
        }`;
    }
  };

  const getTotalComplaints = useMemo(() => {
    if (isValidArray(props.complaints.summaryData)) {
      return Object.values(
        props.complaints.summaryData.find(
          (data) => data.title === "COMPLAINTS STATUS DATA"
        ).count
      )
        .reduce((sum, value) => sum + value, 0)
        ?.toString()
        ?.padStart(2, "0");
    }
  }, [props.complaints.summaryData]);
  return (
    <>
      <div
        className={`${
          props.enableFilter
            ? "remaining-body-height-from-header"
            : getRemainingHeight()
        } inherit-parent-width flex-justify-content-center display-flex ${
          props.activeSwitch === "MAP" || props.enableFilter
            ? ""
            : "table-background-image"
        }  `}
      >
        <div
          className={` ${
            props.activeSwitch === "MAP" || props.enableFilter
              ? "inherit-parent-width"
              : "width-75-percentage"
          }`}
        >
          {/* Map Area */}
          <div
            ref={mapContainer}
            className={`${
              !window.location.pathname === "/" ||
              props.showLoginModal ||
              (props.enableSearch && props.searchKey.length >= 1) ||
              props.activeSwitch === "TABLE"
                ? "display-none"
                : ""
            } inherit-parent-height inherit-parent-width over-flow-hidden `}
            data-cy={`${
              !window.location.pathname === "/" ||
              props.showLoginModal ||
              (props.enableSearch && props.searchKey.length >= 1) ||
              props.activeSwitch !== "MAP"
                ? ""
                : "map-page"
            }`}
          />

          {props.activeSwitch === "MAP" &&
            !props.enableFilter &&
            !props.table.showModal?.summary &&
            props.table.selectedTable === tableTitles.workForce &&
            !isValidObject(props.tracks.data) &&
            props.claims?.beta && (
              <div
                style={{
                  position: "absolute",
                  top: "130px"
                }}
                className="height-fit-content width-fit-content position-absolute z-index-1 bottom-32px left-32px"
              >
                <InfoCard
                  loading={props.tracks.loading}
                  theme={props.theme}
                  title={"NO TRACKING AVAILABLE FOR APPLIED DATE"}
                />
              </div>
            )}

          {props.activeSwitch === "MAP" &&
            !props.enableFilter &&
            !props.table.showModal?.summary &&
            props.table.selectedTable === tableTitles.workForce &&
            isValidObject(props.tracks.data) &&
            props.claims?.beta && (
              <div
                style={{
                  position: "absolute",
                  top: "130px"
                }}
                className="height-fit-content width-fit-content position-absolute z-index-1 bottom-32px left-32px"
              >
                <RefreshCard
                  theme={props.theme}
                  loading={props.tracks.loading || props.tracks.trackerLoading}
                  title={
                    props.tracks.lastUpdatedAt
                      ? `Last Updated - ${dateAndTimeConverter(
                          props.tracks.lastUpdatedAt,
                          "Time"
                        )}, ${dateAndTimeConverter(
                          props.tracks.lastUpdatedAt,
                          "cardDate"
                        )}`
                      : "Refresh"
                  }
                  refreshOnClick={(e) => {
                    rippleEffect(e);
                    props.lastUpdatedAt();
                    drawRoutesInMap(props.tracks.data, props.employees.data);
                  }}
                />
              </div>
            )}
          {/* Locations Table */}
          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            !props.table.showModal?.summary &&
            props.table.selectedTable === tableTitles.locations && (
              <LocationTable
                hits={props.hits}
                enableSearch={props.enableSearch}
                complaints={props.complaints}
                procedures={props.procedures}
                navigate={props.navigate}
                locationFilter={props.table.locations.filter}
                theme={props.theme}
                summaryClick={() => {
                  props.setShowModal({
                    summary: tableTitles.locations,
                    create: null
                  });
                }}
                setSelectedTableData={(documentId) => {
                  props.setSelectedTableData(tableTitles.locations, documentId);
                  props.setEnableFilter(false);
                }}
              />
            )}

          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            props.table.showModal?.summary === tableTitles.locations && (
              <LocationSummary
                hits={props.hits}
                summaryData={props.locations.graphSummaryData}
                navigate={props.navigate}
                theme={props.theme}
                summaryClick={() => {
                  props.setShowModal({
                    summary: null,
                    create: null
                  });
                }}
              />
            )}

          {/* Complaints Table */}
          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            !props.table.showModal?.summary &&
            props.table.selectedTable === tableTitles.complaints && (
              <div
                className="remaining-body-height-from-footer inherit-parent-height"
                style={{
                  minWidth: "700px"
                }}
                data-cy={"complaints-table"}
              >
                {isValidArray(props.complaints.data) && (
                  <Table
                    showSummary={true}
                    headers={complaintsTableHeadings}
                    paginationLoading={
                      props.complaintsLoading.pagination ||
                      props.complaints.paginationLoading
                    }
                    onScrollReachedEnd={() => {
                      if (props.complaints.paginationLoading === false) {
                        props.getComplaintsNextPage();
                      }
                    }}
                    summaryClick={() => {
                      props.setShowModal({
                        summary: tableTitles.complaints,
                        create: null
                      });
                    }}
                    showExport={true}
                    exportLoading={props.complaintsLoading.export}
                    exportClick={() => {
                      if (
                        !props.complaintsLoading.export &&
                        props.complaints.data.length > 0
                      ) {
                        props.downloadComplaintsReport();
                      }
                    }}
                    falseData={
                      <div className="display-flex">
                        <div
                          style={{
                            height: "44px"
                          }}
                          className="padding-left-large font-size-medium display-flex flex-align-items-center padding-right-larger"
                        >
                          {props.falseData ||
                            `TOTAL COMPLAINTS ${getTotalComplaints}`}
                        </div>
                      </div>
                    }
                    data={props.complaints.data}
                    onClick={(data) => {
                      props.setSelectedTableData(tableTitles.complaints, data);
                      props.setEnableFilter(false);
                      props.getComplaintsAssets(data.documentId);
                    }}
                    handleSort={(data) => {
                      props.handleComplaintSort(data);
                    }}
                    sort={props.complaintsSortConfig}
                    getField={complaintsTableField}
                    theme={props.theme}
                  />
                )}

                {!isValidArray(props.complaints.data) && (
                  <div
                    className="parent-height-sub-42px inherit-parent-width font-size-default display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
                    data-cy="no-search-or-filter-fallback"
                  >
                    <img
                      src={SearchFallBackIcon}
                      alt="no filter result found"
                    />
                    <div className=" padding-top-larger">
                      NO MATCHING RESULTS
                    </div>
                    <div className=" padding-top-large">
                      There wasn’t any result matching to the search and filter
                    </div>
                  </div>
                )}
              </div>
            )}
          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            props.table.showModal?.summary === tableTitles.complaints && (
              <ComplaintSummary
                setFilter={(key, value) => {
                  props.setTableFilter(tableTitles.complaints, {
                    ...props.table?.[tableTitles.complaints?.toLowerCase()]
                      .filter,
                    [key]: [value]
                  });
                  props.setShowModal({
                    summary: null,
                    create: null
                  });
                }}
                hits={props.hits}
                navigate={props.navigate}
                summaryData={props.complaints.summaryData}
                theme={props.theme}
                summaryClick={() => {
                  props.setShowModal({
                    summary: null,
                    create: null
                  });
                }}
              />
            )}

          {/* Procedure History Table */}
          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            props.table.selectedTable === tableTitles.procedures && (
              <ProcedureHistoryTable
                auth={props.auth}
                procedures={props.procedures}
                complaintsNextPageNo={props.complaintsNextPageNo}
                proceduresFilterQuery={props.proceduresFilterQuery}
                getProcedureHistoryNextPage={props.getProcedureHistoryNextPage}
                setSelectedTableData={(documentId) => {
                  props.setSelectedTableData(
                    tableTitles.procedures,
                    documentId
                  );
                  props.setEnableFilter(false);
                  props.getProcedureHistoryAssets(documentId);
                }}
                table={props.table}
                employees={props.employees}
                locations={props.locations}
                theme={props.theme}
              />
            )}

          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            !props.table.showModal?.summary &&
            props.table.selectedTable === tableTitles.workForce && (
              <EmployeeTable
                // complaintsNextPageNo={props.complaintsNextPageNo}
                // getProcedureHistoryNextPage={props.getProcedureHistoryNextPage}
                setSelectedTableData={(documentId) => {
                  props.setSelectedTableData(tableTitles.workForce, documentId);
                  props.setEnableFilter(false);
                }}
                table={props.table}
                summaryClick={() => {
                  props.setShowModal({
                    summary: tableTitles.workForce,
                    create: null
                  });
                }}
                createClick={() => {
                  props.setShowModal({
                    summary: null,
                    create: tableTitles.workForce
                  });
                }}
                employees={props.employees}
                locations={props.locations}
                theme={props.theme}
              />
            )}

          {props.activeSwitch !== "MAP" &&
            !props.enableFilter &&
            props.table.showModal?.summary === tableTitles.workForce && (
              <WorkForceSummary
                hits={props.hits}
                navigate={props.navigate}
                theme={props.theme}
                summaryClick={() => {
                  props.setShowModal({
                    summary: null,
                    create: null
                  });
                }}
              />
            )}

          {/* filter screen */}
          {props.activeSwitch !== "MAP" && props.enableFilter && (
            <>
              <div className=" inherit-parent-height padding-horizontal-larger min-width-600px max-width-100-percentage display-flex display-flex flex-justify-content-center overflow-hidden">
                {/* Location Filter screen */}
                {props.table.selectedTable === tableTitles.locations && (
                  <>
                    <Filter
                      filterData={[
                        {
                          title: "category",
                          type: "text",
                          value: [
                            "community",
                            "institutional",
                            "publicSpace",
                            "transitArea"
                          ],
                          showCountValue: true,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {},
                          horizontal: "left",
                          vertical: "top",
                          name: "category",
                          dataCy: "category"
                        },
                        {
                          title: "zone",
                          value: getUniqueDataFromObj(
                            "zone",
                            props.locations.data
                          ),
                          countValue: "",
                          type: "text",
                          horizontal: "left",
                          vertical: "middle",
                          name: "zone",
                          dataCy: "zone",
                          showCountValue: true,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {}
                        },
                        {
                          title: "status",
                          value: ["Red", "Green", "Yellow"],
                          type: "text",
                          name: "status",
                          horizontal: "left",
                          vertical: "bottom",
                          dataCy: "status",
                          showCountValue: false,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {}
                        },

                        {
                          title: "date range",
                          horizontal: "right",
                          vertical: "top",
                          value: "",
                          name: "dateRange",
                          type: "dateRangeForm",
                          dataCy: "date-range-form"
                        },
                        {
                          title: "ward",
                          value: getUniqueDataFromObj(
                            "ward",
                            props.locations.data
                          ),
                          countValue: "",
                          type: "text",
                          name: "ward",
                          horizontal: "right",
                          vertical: "bottom",
                          dataCy: "ward",
                          showCountValue: true,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {}
                        }
                      ]}
                      dataCy="locations-history-filter"
                      locationData={props.locations.data}
                      selectedFilter={props.table?.locations?.filter}
                      setFilter={(filterData) => {
                        props.setTableFilter(tableTitles.locations, filterData);
                      }}
                      table={props.table}
                      theme={props.auth.theme}
                    />
                  </>
                )}

                {/* Workforce Filter screen */}
                {props.table.selectedTable === tableTitles.workForce && (
                  <>
                    <Filter
                      filterData={[
                        {
                          title: "date range",
                          horizontal: "right",
                          vertical: "top",
                          value: "",
                          name: "date",
                          type: "dateForm",
                          dataCy: "date-range-form"
                        }
                      ]}
                      dataCy="locations-history-filter"
                      selectedFilter={props.table?.workForce?.filter}
                      setFilter={(filterData) => {
                        props.setTableFilter(tableTitles.workForce, filterData);
                      }}
                      table={props.table}
                      theme={props.auth.theme}
                    />
                  </>
                )}

                {/* Procedures Filter screen */}
                {props.table.selectedTable === tableTitles.procedures && (
                  <Filter
                    filterData={[
                      {
                        title: "Assigned to",
                        value: "",
                        inputLabel: "phone number",
                        inputPlaceholder: "ENTER PHONE NUMBER",
                        type: "inputBox",
                        horizontal: "left",
                        vertical: "top",
                        name: "assignedTo",
                        dataCy: "assigned-to",
                        findEmployeeId: true,
                        inputOnChange: (employeeId) => {
                          props.setTableFilter(tableTitles.procedures, {
                            ...props.table?.procedures?.filter,
                            assignedTo: [employeeId]
                          });
                        }
                      },
                      {
                        title: "Procedure name",
                        type: "text",
                        value: isValidObject(props.procedures.data)
                          ? Object.values(props.procedures.data)
                          : [],
                        horizontal: "left",
                        vertical: "middle",
                        name: "procedureName",
                        requiredBorder: true,
                        dataCy: "procedure-name"
                      },

                      {
                        title: "Procedure type",
                        value: ["employee", "logistics", "sensor"],
                        type: "text",
                        name: "procedureType",
                        horizontal: "right",
                        vertical: "bottom",
                        requiredBorder: true,
                        dataCy: "procedure-type"
                      },
                      {
                        title: "Date range",
                        horizontal: "right",
                        vertical: "top",
                        value: "",
                        name: "dateRange",
                        type: "dateRangeForm",
                        dataCy: "date-range-form"
                      }
                    ]}
                    dataCy="procedure-history-filter"
                    selectedFilter={props.table?.procedures?.filter}
                    setFilter={(filterData) => {
                      props.setTableFilter(tableTitles.procedures, filterData);
                    }}
                    employees={props.employees}
                    theme={props.auth.theme}
                  />
                )}

                {/* Complaints Filter */}
                {props.table.selectedTable === tableTitles.complaints && (
                  <>
                    <Filter
                      filterData={[
                        {
                          title: "issue raised by",
                          value: "",
                          type: "inputBox",
                          inputLabel: "Phone number",
                          inputPlaceholder: "ENTER PHONE NUMBER ",
                          horizontal: "left",
                          vertical: "top",
                          name: "issueRaised",
                          dataCy: "complaints-issue-raised-by",
                          inputOnChange: (data) => {
                            if (
                              typeof data === "string" &&
                              data?.length === 10 &&
                              isDigit(data)
                            ) {
                              props.setTableFilter(tableTitles.complaints, {
                                ...props.table?.complaints?.filter,
                                issueRaised: [`+91${data}`]
                              });
                            }
                          }
                        },
                        {
                          title: "status",
                          value: ["OPEN", "CLOSED", "UNDER REVIEW"],
                          horizontal: "left",
                          vertical: "middle",
                          dataCy: "complaints-status",
                          name: "status",
                          type: "text",
                          showComplaintCount: false
                        },
                        {
                          title: "category",
                          value: complaintsCategory,
                          type: "text",
                          dataCy: "complaints-category",
                          name: "category",
                          horizontal: "left",
                          vertical: "bottom"
                        },
                        {
                          title: "zone",
                          value: getUniqueDataFromObj(
                            "zone",
                            props.locations.data
                          ),
                          countValue: "",
                          type: "text",
                          horizontal: "left",
                          vertical: "middle",
                          name: "zone",
                          dataCy: "zone",
                          showCountValue: false,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {}
                        },
                        {
                          title: "ward",
                          value: getUniqueDataFromObj(
                            "ward",
                            props.locations.data
                          ),
                          countValue: "",
                          type: "text",
                          name: "ward",
                          horizontal: "left",
                          vertical: "bottom",
                          dataCy: "ward",
                          showCountValue: false,
                          getCountFrom: isValidObject(props.locations.data)
                            ? props.locations.data
                            : {}
                        },
                        {
                          title: "issue taken by",
                          value: "",
                          type: "inputBox",
                          inputLabel: "Mobile / Email",
                          inputPlaceholder: "ENTER EMAIL / PHONE NUMBER",
                          horizontal: "right",
                          vertical: "top",
                          name: "issueTakenBy",
                          dataCy: "complaints-issue-taken-by",
                          inputOnChange: (data) => {
                            if (
                              typeof data === "string" &&
                              data?.length === 10 &&
                              isDigit(data)
                            ) {
                              props.setTableFilter(tableTitles.complaints, {
                                ...props.table?.complaints?.filter,
                                issueTakenBy: [`+91${data}`]
                              });
                            } else if (Regex.email.test(data)) {
                              props.setTableFilter(tableTitles.complaints, {
                                ...props.table?.complaints?.filter,
                                issueTakenBy: [data.toLowerCase()]
                              });
                            }
                          }
                        },

                        {
                          title: "date range",
                          horizontal: "right",
                          vertical: "middle",
                          value: "",
                          name: "dateRange",
                          type: "dateRangeForm",
                          dataCy: "date-range-form"
                        },

                        {
                          title: "locations",
                          value: getLocationList(props.locations.data),
                          horizontal: "right",
                          vertical: "bottom",
                          name: "PCTNo",
                          requiredBorder: true,
                          dataCy: "complaints-location",
                          type: "text"
                        }
                      ]}
                      setFilter={(filterData) => {
                        props.setTableFilter(
                          tableTitles.complaints,
                          filterData
                        );
                      }}
                      selectedFilter={props.table?.complaints?.filter}
                      table={props.table}
                      complaints={props.complaints.data}
                      theme={props.auth.theme}
                      employees={props.employees}
                      dataCy="complaints-history-filter"
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        show={
          props.activeSwitch !== "MAP" &&
          props.table.selectedTable === tableTitles.workForce &&
          props.table.showModal?.create === tableTitles.workForce &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setShowModal({
            create: null,
            summary: null
          });
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-480px"
        width="width-1100px"
        showFrame={true}
      >
        <EmployeeCreate
          roles={props.employees.roles}
          employeeData={props.employees.data}
          createEmployee={(data) => {
            props.createEmployee(data);
          }}
          closeModal={() => {
            props.setShowModal({
              summary: null,
              create: null
            });
          }}
          navigate={props.navigate}
          theme={props.theme}
          summaryClick={() => {
            props.setShowModal({
              summary: null,
              create: null
            });
          }}
          claims={props.claims}
        />
      </Modal>

      {/* Location preview modal */}
      <Modal
        show={
          props.activeSwitch !== "MAP" &&
          props.table.selectedTable === tableTitles.locations &&
          props.table.locations.selectedLocation &&
          props.locations.data?.[props.table.locations.selectedLocation] &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableData(tableTitles.locations, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-480px"
        width="width-1100px"
        showFrame={true}
      >
        <LocationsPreview
          locationId={props.table.locations.selectedLocation}
          locationData={
            props.locations.data?.[props.table.locations.selectedLocation] &&
            props.locations.data?.[props.table.locations.selectedLocation]
          }
          roles={props.employees.roles}
          selectedSummaryData={
            props.employees.captureSummary?.[
              props.table.locations.selectedLocation
            ]
          }
          selectedTable={props.table.locations}
          setSelectedTable={(table, locationId) => {
            props.setTableFilter(table, {
              ...props.table?.[table?.toLowerCase()].filter,
              PCTNo: [props.locations.data?.[locationId]?.PCTNo]
            });
            setSelectedTable(table);
          }}
          auth={props.auth}
          theme={props.theme}
        />
      </Modal>

      {/* Complaint preview modal */}
      <Modal
        show={
          props.table.selectedTable === tableTitles.complaints &&
          props.table.complaints.selectedComplaint &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableData(tableTitles.complaints, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-480px"
        width="width-1100px"
        showFrame={true}
      >
        <ComplaintsPreview
          compliantData={props.table.complaints.selectedComplaint}
          locationData={
            props.locations.data?.[
              props.table.complaints.selectedComplaint?.location?.id
            ]
          }
          complaintsAssetsLoading={props.complaintsLoading.assets}
          compliantLoading={props.complaints.loading}
          auth={props.auth}
          employeeData={props.employees?.data}
          setPreviewImage={(image) => {
            setPreviewImage(image);
          }}
          fixComplaints={(data) => {
            fixComplaints(
              data.documentId,
              data.locationId,
              props.auth.data.uid,
              data.proof
            );
          }}
          complaintTakeover={(documentId) => {
            complaintTakeover(documentId, {
              uid: props.auth.data.uid,
              email: props.auth.data.email
            });
          }}
          verifyCompliant={(data, type) => {
            verifyCompliant(data, type);
          }}
          // assetsLoading={props.proceduresAssetsLoading}
          sensors={props.sensors}
          theme={props.theme}
        />
      </Modal>

      {/* Procedure preview modal */}
      <Modal
        show={
          props.table.selectedTable === tableTitles.procedures &&
          props.table.procedures.selectedProcedure &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableData(tableTitles.procedures, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-480px"
        width="width-1100px"
        showFrame={true}
      >
        <ProceduresPreview
          procedures={
            props.procedures.data?.[
              props.procedures.history?.[
                props.table.procedures.selectedProcedure
              ]?.procedureId
            ]
          }
          proceduresHistory={
            props.procedures.history?.[props.table.procedures.selectedProcedure]
          }
          locationData={
            props.locations.data?.[
              props.procedures.history?.[
                props.table.procedures?.selectedProcedure
              ]?.locationId
            ]
          }
          employeeData={{
            ...props.employees?.data?.[
              props.procedures.history?.[
                props.table.procedures?.selectedProcedure
              ]?.employeeId
            ]
          }}
          setPreviewImage={(image) => {
            setPreviewImage(image);
          }}
          assetsLoading={props.proceduresAssetsLoading}
          sensors={props.sensors}
          theme={props.theme}
        />
      </Modal>

      {/* employee preview modal */}
      <Modal
        show={
          props.activeSwitch !== "MAP" &&
          props.table.selectedTable === tableTitles.workForce &&
          props.table.workForce?.selectedEmployee &&
          props.employees?.data?.[props.table.workForce?.selectedEmployee] &&
          !props.enableFilter
        }
        canIgnore={true}
        onClose={() => {
          props.setSelectedTableData(tableTitles.workForce, null);
        }}
        borderRadius="false"
        boxShadow="box-shadow-none"
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        modalBodyClassName="box-shadow-none"
        height="height-480px"
        width="width-1100px"
        showFrame={true}
      >
        <EmployeesPreview
          claims={props.claims}
          employeeId={props.table.workForce?.selectedEmployee}
          employeeLoading={props.employees.loading}
          employeeData={
            props.employees?.data?.[props.table.workForce?.selectedEmployee]
          }
          roles={props.employees?.roles}
          addRolesToSelectedEmployee={(documentId, roles) => {
            props.addRolesToSelectedEmployee(documentId, roles);
          }}
          surveillanceLoading={props.surveillance.loading}
          referencePictureData={
            isValidObject(props.surveillance.reference) &&
            Object.values(props.surveillance.reference).find(
              (data) =>
                data.employee?.id === props.table.workForce?.selectedEmployee
            )
          }
          capturePictureData={
            isValidObject(props.surveillance.captures) &&
            Object.values(props.surveillance.captures)
              .filter(
                (data) =>
                  data.employee.id === props.table.workForce?.selectedEmployee
              )
              .sort((a, b) => b.createdAt - a.createdAt)
          }
          setSelectedTable={(table, locationId) => {
            props.setTableFilter(table, {
              ...props.table?.[table?.toLowerCase()].filter,
              PCTNo: [props.locations.data?.[locationId]?.PCTNo]
            });
            setSelectedTable(table);
          }}
          createPicture={(data, file) => {
            props.createPicture(data, file);
          }}
          editPicture={(data, file, documentId) => {
            props.editPicture(data, file, documentId);
          }}
          auth={props.auth}
          devices={props.surveillance.devices}
          status={
            props.surveillance.status?.[props.table.workForce?.selectedEmployee]
          }
          surveillanceRetryLoading={props.surveillance.retryLoading}
          retryPictureUploadToDevices={(documentId) => {
            retryPictureUploadToDevices(documentId);
          }}
        />
      </Modal>

      {/* Image preview modal */}
      <Modal
        show={typeof previewImage === "string"}
        canIgnore={true}
        onClose={() => {
          setPreviewImage(null);
        }}
        borderRadius="false"
        boxShadow="false"
      >
        <div
          className="display-flex flex-justify-content-center"
          data-cy={"view-image-modal"}
        >
          <img src={previewImage} height={500} alt="preview" />
        </div>
      </Modal>
      {document.addEventListener("keydown", function(event) {
        if (event.key === "Escape" && previewImage !== null) {
          setPreviewImage(null);
        }
      })}
    </>
  );
}

const mapStateToProps = function(state) {
  return {
    complaints: state.complaints,
    procedures: state.procedures,
    surveillance: state.surveillance,
    locations: state.locations,
    table: state.table,
    employees: state.employees,
    auth: state.auth,
    tracks: state.tracks
  };
};

const mapDispatchToProps = function() {
  return {
    getTotalComplaints: (data) => getTotalComplaints(data),
    lastUpdatedAt: () => lastUpdatedAt(),
    getComplaintSummary: () => getComplaintSummary(),
    getRolesData: () => getRolesData(),
    addRolesToSelectedEmployee: (documentId, roles) =>
      addRolesToSelectedEmployee(documentId, roles),
    setTableFilter: (table, filter) => setTableFilter(table, filter),
    createPicture: (data, file) => createPicture(data, file),
    createEmployee: (data) => createEmployee(data),
    editPicture: (data, file, documentId) =>
      editPicture(data, file, documentId),
    getProceduresAssets: (locationId, selectedComplaint, index) =>
      getProceduresAssets(locationId, selectedComplaint, index),
    setShowModal: (data) => setShowModal(data),
    setSelectedTableData: (tableName, data) =>
      setSelectedTableData(tableName, data)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

function InfoCard(props) {
  return (
    <div
      data-cy="location-tracker-info-card"
      style={{
        width: "400px"
      }}
      className="display-flex info-card-box-shadow border-1px-e5e5e5 font-color-white flex-direction-column flex-align-items-center  font-size-default background-color-dark padding-large border-radius-32px"
    >
      <div className="corner top-left" />
      <div className="corner top-right" />
      <div className="corner bottom-left" />
      <div className="corner bottom-right" />
      <div className="display-flex flex-align-items-center flex-justify-content-center">
        {props.loading ? (
          <div
            style={{
              height: "16px",
              width: "16px"
            }}
          >
            <div className="qr-loader" />
          </div>
        ) : (
          <div
            style={{
              height: "20px",
              width: "20px"
            }}
          >
            <LocationIcon height="22" width="22" color="white" />
          </div>
        )}

        <div className="font-size-medium padding-left-large">
          {props.loading ? "Fetching Latest Data..." : props.title}
        </div>
      </div>
    </div>
  );
}

function RefreshCard(props) {
  return (
    <div
      onClick={props.refreshOnClick}
      data-cy="location-tracker-info-card"
      style={{
        width: "260px",
        height: "48px"
      }}
      className="display-flex cursor-pointer ripple-element info-card-box-shadow border-1px-e5e5e5 font-color-white flex-direction-column flex-align-items-center  font-size-default background-color-dark padding-large border-radius-32px"
    >
      <div className="corner top-left" />
      <div className="corner top-right" />
      <div className="corner bottom-left" />
      <div className="corner bottom-right" />
      <div className="display-flex flex-align-items-center flex-justify-content-center">
        {props.loading ? (
          <div
            className="display-flex flex-justify-content-center flex-align-items-center"
            style={{
              height: "14px",
              width: "14px"
            }}
          >
            <div className="qr-loader" />
          </div>
        ) : (
          <div
            style={{
              height: "14px",
              width: "14px"
            }}
          >
            <RefreshIcon height="16" width="15" color="white" />
          </div>
        )}

        <div className="font-size-medium padding-top-smaller padding-left-large">
          {props.loading ? "Fetching Latest Data..." : props.title}
        </div>
      </div>
    </div>
  );
}
