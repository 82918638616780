import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  addRolesToEmployee,
  assignProcedureToEmployee,
  createNewEmployee,
  deleteLinkedProcedures,
  getRolesData,
  updateEmployeeId,
} from "../../Services/database";
import { isValidArray } from "../../Services/validators";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import store from "../store";
import { createPicture } from "../surveillance/action";

export const actionTypes = {
  PUT_EMPLOYEES_DATA: "PUT_EMPLOYEES_DATA",
  ADD_CAPTURE_SUMMARY_DATA: "ADD_CAPTURE_SUMMARY_DATA",
  GET_ROLES_DATA: "GET_ROLES_DATA",
  ADD_ROLES_DATA: "ADD_ROLES_DATA",
  CREATE_EMPLOYEE: "CREATE_EMPLOYEE",
  DELETE_LINKED_PROCEDURE: "DELETE_LINKED_PROCEDURE",
  ASSIGN_PROCEDURE_TO_EMPLOYEE: "ASSIGN_PROCEDURE_TO_EMPLOYEE",
};

function* setEmployeesDataWorker(action) {
  yield put({
    type: "SET_EMPLOYEES_DATA",
    payload: {
      data: action.payload.data,
    },
  });
}
function* setCaptureSummaryDataWorker(action) {
  yield put({
    type: "SET_CAPTURE_SUMMARY_DATA",
    payload: {
      data: action.payload.data,
    },
  });
}

function* getRolesDataWorker() {
  try {
    yield setEmployeesLoading(true);
    const data = yield getRolesData();
    yield put({
      type: "SET_ROLES_DATA",
      payload: {
        data: data,
      },
    });
    yield setEmployeesLoading(false);
  } catch (error) {
    yield setEmployeesLoading(false);
    setErrorStatus(error);
    console.error(error, "getRolesDataWorker");
  }
}

function* addRolesDataWorker(action) {
  try {
    yield setEmployeesLoading(true);
    yield addRolesToEmployee(action.payload.documentId, action.payload.roles);
    yield setSuccessStatus("Roles Updated successfully");
    yield setEmployeesLoading(false);
  } catch (error) {
    yield setEmployeesLoading(false);
    setErrorStatus(error);
    console.error(error, "addRolesDataWorker");
  }
}

function* createEmployeeWorker(action) {
  try {
    yield setEmployeesLoading(true);

    const { data } = action.payload;
    const { workForceType, contactInfo, name, file } = data;

    const existingEmployees = store.getState().employees.data;

    const employeeExists = Object.values(existingEmployees).some(
      (employee) =>
        (workForceType === "admin" && employee.email === contactInfo) ||
        (workForceType !== "admin" && employee.phoneNumber === contactInfo)
    );

    if (employeeExists) {
      yield setErrorStatus({
        code: "custom",
        message: "Employee already exists.",
      });
      yield setEmployeesLoading(false);
      return;
    }

    const documentId = yield createNewEmployee({
      ...(workForceType === "admin"
        ? { email: contactInfo }
        : { phoneNumber: contactInfo }),
      name,
      type: workForceType,
    });

    // addRolesToSelectedEmployee(documentId, roles);

    if (file) {
      createPicture(
        {
          name,
          employee: {
            phoneNumber: contactInfo,
            name,
            id: documentId,
          },
        },
        file
      );
    }

    if (documentId) {
      yield updateEmployeeId(documentId);
      yield setSuccessStatus("Employee created successfully");
    }

    yield setEmployeesLoading(false);
  } catch (error) {
    yield setEmployeesLoading(false);
    yield setErrorStatus(error.message);
  }
}

function* assignProcedureToEmployeeWorker(action) {
  try {
    yield setEmployeesLoading(true);
    let data = action.payload.data;
    if (isValidArray(data)) {
      if (
        data?.every(
          (data) =>
            data.to !== action.payload.current.to &&
            data.from !== action.payload.current.from
        )
      ) {
        return;
      } else {
        data = data.map((item) => {
          item.from = action.payload.current.from;
          item.to = action.payload.current.to;
          return item;
        });
      }
      yield assignProcedureToEmployee(action.payload.employeeId, [
        ...data,
        ...(isValidArray(
          store.getState().employees.data?.[action.payload.employeeId]
            .linkedProcedures
        )
          ? store
              .getState()
              .employees.data?.[
                action.payload.employeeId
              ].linkedProcedures?.filter(
                (linkedProcedure) =>
                  linkedProcedure.procedureId !== data[0].procedureId
              )
          : []),
      ]);
      setSuccessStatus("Procedure Assigned Successfully");
    } else if (typeof data === "string" && data === "clearAll") {
      yield assignProcedureToEmployee(action.payload.employeeId, []);
      setSuccessStatus("Procedure Removed Successfully");
    }
    yield setEmployeesLoading(false);
  } catch (error) {
    yield setEmployeesLoading(false);
    setErrorStatus(error, "from assign procedure");
  }
}

function* deleteLinkedProcedureWorker(action) {
  try {
    yield setEmployeesLoading(true);
    yield deleteLinkedProcedures(
      action.payload.employeeId,
      action.payload.data
    );

    yield setSuccessStatus("Procedure removed successfully");
    yield setEmployeesLoading(false);
  } catch (error) {
    yield setEmployeesLoading(false);
    setErrorStatus(error);
  }
}

export default function* employeesWatcher() {
  yield all([
    takeEvery("PUT_EMPLOYEES_DATA", setEmployeesDataWorker),
    takeEvery("ADD_CAPTURE_SUMMARY_DATA", setCaptureSummaryDataWorker),
    takeEvery("GET_ROLES_DATA", getRolesDataWorker),
    takeEvery("ADD_ROLES_DATA", addRolesDataWorker),
    takeEvery("CREATE_EMPLOYEE", createEmployeeWorker),
    takeEvery("DELETE_LINKED_PROCEDURE", deleteLinkedProcedureWorker),
    takeEvery("ASSIGN_PROCEDURE_TO_EMPLOYEE", assignProcedureToEmployeeWorker),
  ]);
}

function* setEmployeesLoading(bool) {
  yield put({
    type: "SET_EMPLOYEES_LOADING",
    payload: {
      loading: bool,
    },
  });
}
