import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import { uploadCompliantProof } from "../../Services/storage";
import { isValidArray, isValidObject } from "../../Services/validators";
import {
  getComplaintsSummary,
  getComplaintsSummaryCount,
  getAverageDurationOfClosedComplaints,
  takeoverCompliant,
  updateCompliant,
  updateVerifyCompliant
} from "../../Services/database";
import store from "../store";
import { verifyCompliant } from "./action";
import {
  bucketNames,
  complaintCategories,
  getDuration,
  getLastSixMonthsTimestamp,
  getUniqueDataFromObj,
  tableTitles
} from "../../Utils/constants";
import { setSelectedTableData } from "../table/action";

export const actionTypes = {
  PUT_COMPLAINTS_DATA: "PUT_COMPLAINTS_DATA",
  GET_ALL_COMPLAINTS_COUNTS: "GET_ALL_COMPLAINTS_COUNTS",
  PUT_COMPLAINTS_EXPORT_LOADING: "PUT_COMPLAINTS_EXPORT_LOADING",
  VERIFY_COMPLAINT: "VERIFY_COMPLAINT",
  GET_COMPLAINTS_ASSETS: "GET_COMPLAINTS_ASSETS",
  GET_TOTAL_VALUES: "GET_TOTAL_VALUES",
  FIX_COMPLAINTS: "FIX_COMPLAINTS",
  COMPLAINT_TAKEOVER: "COMPLAINT_TAKEOVER"
};

function* getAllComplaintsCountWorker(action) {
  try {
    yield setSummaryLoading(true);
    const promises = [
      getComplaintsSummaryCount([...action.payload.filter]), // Total count
      getComplaintsSummaryCount([
        ...action.payload.filter,
        "issuedBy.type",
        "==",
        "customerSupport"
      ]), // issuedBy Customer Support count
      getComplaintsSummaryCount([
        ...action.payload.filter,
        "issuedBy.type",
        "==",
        "public"
      ]), // issuedBy Public count
      getComplaintsSummaryCount([
        ...action.payload.filter,
        "issuedBy.type",
        "==",
        "procedure"
      ]) // issuedBy Procedure (Internal) count
    ];

    let graphData = [];

    // get COMPLAINTS RAISED DATA filter and get LONGEST DURATION COMPLAINTS filter
    let updatedFilter = action.payload.filter?.filter(
      (value, index) =>
        value !== "status.currentStatus" &&
        action.payload.filter[index - 1] !== "status.currentStatus" &&
        action.payload.filter[index - 2] !== "status.currentStatus" &&
        value
    );

    promises.push(
      getComplaintsSummaryCount([
        ...updatedFilter,
        "status.currentStatus",
        "==",
        "OPEN"
      ]), // open status count
      getComplaintsSummaryCount([
        ...updatedFilter,
        "status.currentStatus",
        "==",
        "UNDER REVIEW"
      ]), // review status count
      getComplaintsSummaryCount([
        ...updatedFilter,
        "status.currentStatus",
        "==",
        "CLOSED"
      ]), // closed status count
      getComplaintsSummary(
        [
          ...updatedFilter,
          "status.currentStatus",
          "in",
          ["OPEN", "UNDER REVIEW"]
        ],
        { field: "createdAt", direction: "asc" },
        5
      ) // longestDurationComplaints
    );
    updatedFilter = updatedFilter?.filter(
      (value, index) =>
        value !== "createdAt" &&
        updatedFilter[index - 1] !== "createdAt" &&
        updatedFilter[index - 2] !== "createdAt" &&
        value
    );

    promises.push(
      getComplaintsSummaryCount([
        ...updatedFilter,
        "closedDuration",
        "<",
        86400000
      ]), // closed complaints less than one day
      getComplaintsSummaryCount([
        ...updatedFilter,
        "closedDuration",
        ">=",
        86400000,
        "closedDuration",
        "<",
        259200000
      ]), // closed complaints 1 to 3 days
      getComplaintsSummaryCount([
        ...updatedFilter,
        "closedDuration",
        ">=",
        259200000,
        "closedDuration",
        "<",
        604800000
      ]), // closed complaints 3 to 7 days
      getComplaintsSummaryCount([
        ...updatedFilter,
        "closedDuration",
        ">=",
        604800000
      ]), // closed complaints more than 7 days
      getAverageDurationOfClosedComplaints(
        isValidArray(updatedFilter) ? updatedFilter : null
      ) // average duration of closed complaints
    );

    // Wait for all promises to resolve
    const [
      totalCount,
      customerSupportCount,
      publicCount,
      internalCount,
      openCount,
      reviewCount,
      closedCount,
      longestDurationComplaints,
      closedBy1dayCount,
      closedBy3daysCount,
      closedBy7daysCount,
      closedByMoreThan7daysCount,
      averageDurationOfClosedComplaints
    ] = yield Promise.all(promises);

    graphData.push({
      title: "COMPLAINTS RAISED DATA",
      count: {
        total: totalCount,
        internal: internalCount,
        public: publicCount,
        customerSupport: customerSupportCount
      }
    });

    graphData.push({
      title: "COMPLAINTS STATUS DATA",
      count: {
        open: openCount,
        review: reviewCount,
        closed: closedCount
      }
    });

    // get COMPLAINTS MONTHLY COMPARISON filter
    // get last 6 month complaints count
    const lastSixMonthsTimestamp = getLastSixMonthsTimestamp();
    const monthlyComparisonData = [];
    for (const data of lastSixMonthsTimestamp) {
      const openComplaintsCount = yield getComplaintsSummaryCount([
        ...updatedFilter,
        "status.currentStatus",
        "in",
        ["OPEN", "UNDER REVIEW"],
        "createdAt",
        ">=",
        data.start,
        "createdAt",
        "<=",
        data.end
      ]);
      const closedComplaintsCount = yield getComplaintsSummaryCount([
        ...updatedFilter,
        "status.currentStatus",
        "in",
        ["CLOSED"],
        "createdAt",
        ">=",
        data.start,
        "createdAt",
        "<=",
        data.end
      ]);

      monthlyComparisonData.push({
        month: data.month,
        count: {
          open: openComplaintsCount,
          closed: closedComplaintsCount,
          total: openComplaintsCount + closedComplaintsCount
        }
      });
    }

    graphData.push({
      title: "COMPLAINTS MONTHLY COMPARISON",
      count: monthlyComparisonData
    });

    // ----------------------------------------------------------------------

    const currentTime = +new Date();
    // Add duration to longestDurationComplaints
    let complaintsWithDuration = [];
    if (longestDurationComplaints) {
      for (const data of longestDurationComplaints) {
        const { totalMilliseconds, totalHours } = getDuration(
          data.createdAt,
          currentTime
        );
        complaintsWithDuration = [
          ...complaintsWithDuration,
          {
            ...data,
            duration: {
              milliseconds: totalMilliseconds,
              hours: totalHours
            }
          }
        ];
      }
    }

    graphData.push({
      title: "COMPLAINTS CLOSURE DURATION",
      count: {
        oneDay: closedBy1dayCount,
        threeDays: closedBy3daysCount,
        sevenDays: closedBy7daysCount,
        moreThanSevenDays: closedByMoreThan7daysCount,
        average: averageDurationOfClosedComplaints
          ? Math.floor(averageDurationOfClosedComplaints / 1000 / 60 / 60)
          : 0
      }
    });

    let zoneAndCategorySummaryFilter = action.payload.filter?.filter(
      (value, index) =>
        (value === "createdAt" ||
          action.payload.filter[index - 1] === "createdAt" ||
          action.payload.filter[index - 2] === "createdAt" ||
          value === "status.currentStatus" ||
          action.payload.filter[index - 1] === "status.currentStatus" ||
          action.payload.filter[index - 2] === "status.currentStatus") &&
        value
    );

    // get complaints counts by using zone
    const zones = getUniqueDataFromObj("zone", store.getState().locations.data);
    const zoneCounts = yield Promise.all(
      zones.map(async (zone) => {
        const count = await getComplaintsSummaryCount([
          ...zoneAndCategorySummaryFilter,
          "location.zone",
          "==",
          zone
        ]);
        return {
          [zone]: count
        };
      })
    ).then((results) => Object.assign({}, ...results));

    graphData.push({
      title: "ZONE WISE COMPLAINTS",
      count: zoneCounts
    });

    // get complaints counts by using categories
    let categoriesCount = yield Promise.all(
      complaintCategories.map(async (category) => {
        const count = await getComplaintsSummaryCount([
          ...zoneAndCategorySummaryFilter,
          "category",
          "in",
          category
        ]);
        return {
          [category[0]]: count
        };
      })
    ).then((results) => Object.assign({}, ...results));

    graphData.push({
      title: "CATEGORY WISE COMPLAINTS",
      count: categoriesCount
    });

    graphData.push({
      title: "LONGEST DURATION COMPLAINTS",
      complaints: complaintsWithDuration
    });

    graphData.push({
      title: "MAX COMPLAINTS RAISED LOCATIONS",
      counts: []
    });

    graphData.push({
      title: "COMING SOON 1",
      counts: []
    });

    yield put({
      type: "SET_COMPLAINT_COUNT_DATA",
      payload: { data: graphData }
    });
    yield setSummaryLoading(false);
  } catch (error) {
    yield setSummaryLoading(false);
    setErrorStatus(error);
    console.error(error, "getAllComplaintsCountWorker");
  }
}

function* putComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);
    yield put({
      type: "SET_COMPLAINTS_DATA",
      payload: {
        data: action.payload.data
      }
    });

    const selectedComplaint = store.getState().table.complaints
      .selectedComplaint;

    console.log("Call updateSelectedComplaintData", selectedComplaint);
    if (isValidObject(selectedComplaint)) {
      const updatedSelectedComplaintData = action.payload.data?.find(
        (data) => data.documentId === selectedComplaint.documentId
      );
      console.log("updatedSelectedComplaintData", updatedSelectedComplaintData);
      if (isValidObject(updatedSelectedComplaintData)) {
        setSelectedTableData(
          tableTitles.complaints,
          updatedSelectedComplaintData
        );
      }
    }

    yield setComplaintsLoading(false);
    yield setComplaintsPaginationLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    yield setComplaintsPaginationLoading(false);
    setErrorStatus(error);
  }
}

function* verifyComplaintWorker(action) {
  try {
    yield setComplaintsLoading(true);
    const data = {
      status: {
        currentStatus: action.payload.type === "close" ? "CLOSED" : "OPEN",
        updatedAt: +new Date()
      },
      closedBy: {
        uid: store.getState().auth.data.uid,
        email: store.getState().auth.data.email
      }
    };

    yield updateVerifyCompliant(action.payload.compliantData.documentId, data);
    yield setSuccessStatus(
      `Complaint ${
        action.payload.type === "close" ? "closed" : "opened"
      } successfully`
    );

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("verifyComplaintWorker", error);
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

// function* getComplaintsAssetsWorker(action) {
//   try {
//     yield setComplaintsAssetsLoading(true);
//     const data = store.getState().complaints?.data?.[
//       action.payload.selectedComplaint
//     ];

//     let complaintFileSrc = {};
//     let complaintImages = [];

//     if (isValidArray(data.assets)) {
//       for (let j = 0; j < data.assets.length; j++) {
//         const file = yield getFileUrl(data.assets[j]);
//         if (file.type === "image") {
//           complaintImages.push(file.url);
//         } else if (file.type === "audio") {
//           complaintFileSrc = { ...complaintFileSrc, audio: file.url };
//         }
//       }
//     }

//     let closureFileSrc = {};
//     let closureImages = [];
//     if (isValidArray(data.closure?.proof)) {
//       for (let j = 0; j < data.closure?.proof.length; j++) {
//         const file = yield getFileUrl(data.closure?.proof[j]);
//         if (file.type === "image") {
//           closureImages.push(file.url);
//         } else if (file.type === "audio") {
//           closureFileSrc = { ...closureFileSrc, audio: file.url };
//         }
//       }
//     }

//     const newData = {
//       ...store.getState().complaints.data,

//       [action.payload.selectedComplaint]: {
//         ...data,
//         fileSrc: {
//           ...complaintFileSrc,
//           ...(isValidArray(complaintImages) ? { images: complaintImages } : {}),
//         },
//         closure: {
//           ...data.closure,
//           fileSrc: {
//             ...closureFileSrc,
//             ...(isValidArray(closureImages) ? { images: closureImages } : {}),
//           },
//         },
//       },
//     };
//     yield setComplaintsAssetsLoading(false);

//     // yield put({
//     //   type: "ADD_COMPLAINTS_DATA",
//     //   payload: { ...newData },
//     // });
//   } catch (error) {
//     yield setComplaintsAssetsLoading(false);

//     setErrorStatus(error);
//   }
// }

function* getTotalValuesWorker(action) {
  try {
    yield setComplaintsLoading(true);

    const daysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const daysInCurrMonth = daysInMonth(currentMonth, currentYear);

    let positive = new Array(daysInCurrMonth).fill(0);
    let negative = new Array(daysInCurrMonth).fill(0);
    if (isValidArray(action.payload.data)) {
      action.payload.data.forEach((element) => {
        if (
          isValidObject(element.procedureCount) &&
          element.procedureCount[currentYear]?.[currentMonth]
        ) {
          const value = Object.entries(
            element.procedureCount[currentYear]?.[currentMonth]
          );
          value.forEach(([day, values]) => {
            positive[parseInt(day)] += values.positive ? values.positive : 0;
            negative[parseInt(day)] += values.negative ? values.negative : 0;
          });
        }
      });
    }

    yield put({
      type: "SET_TOTAL_PROCEDURE_COUNT",
      payload: {
        positive: positive,
        negative: negative
      }
    });

    yield setComplaintsLoading(false);
  } catch (error) {
    yield setComplaintsLoading(false);
    setErrorStatus(error);
  }
}

function* fixComplaintsWorker(action) {
  try {
    yield setComplaintsLoading(true);

    const { proof, complaintId, locationId, employeeId } = action.payload;
    let imageFileUrls = [];
    let audioFileUrls = [];

    if (isValidArray(proof?.image)) {
      const imageUploadResults = yield* uploadFiles(
        proof.image,
        complaintId,
        locationId,
        "closure"
      );

      imageFileUrls = imageUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/${locationId}/complaints/${complaintId}/closure/${result}`
      );
    }

    if (proof.audio) {
      const audioUploadResults = yield* uploadFiles(
        [proof.audio],
        complaintId,
        locationId,
        "closure"
      );
      audioFileUrls = audioUploadResults.map(
        (result) =>
          `${bucketNames.defaultBucket}/${locationId}/complaints/${complaintId}/closure/${result}`
      );
    }

    const filesUrls = [...imageFileUrls, ...audioFileUrls];

    yield updateCompliant(complaintId, employeeId, filesUrls);

    verifyCompliant(
      store.getState().table.complaints.selectedComplaint,
      "close"
    );

    // getComplaintsData(locationId);

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("fixComplaintsWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}

function* complaintTakeoverWorker(action) {
  try {
    yield setComplaintsLoading(true);
    yield takeoverCompliant(
      action.payload.complaintId,
      action.payload.employeeData
    );

    setSuccessStatus("Compliant Taken over successfully");

    yield setComplaintsLoading(false);
  } catch (error) {
    console.error("complaintTakeoverWorker", error);
    setErrorStatus(error);
    yield setComplaintsLoading(false);
  }
}
function* setComplaintExportLoadingWorker(action) {
  yield put({
    type: "SET_COMPLAINTS_EXPORT_LOADING",
    payload: {
      loading: action.payload.loading
    }
  });
}

export default function* complaintsWatcher() {
  yield all([
    takeEvery("PUT_COMPLAINTS_DATA", putComplaintsWorker),
    takeEvery("PUT_COMPLAINTS_EXPORT_LOADING", setComplaintExportLoadingWorker),
    takeEvery("GET_ALL_COMPLAINTS_COUNTS", getAllComplaintsCountWorker),
    takeEvery("VERIFY_COMPLAINT", verifyComplaintWorker),
    // takeEvery("GET_COMPLAINTS_ASSETS", getComplaintsAssetsWorker),
    takeEvery("GET_TOTAL_VALUES", getTotalValuesWorker),
    takeEvery("FIX_COMPLAINTS", fixComplaintsWorker),
    takeEvery("COMPLAINT_TAKEOVER", complaintTakeoverWorker)
  ]);
}

function* setComplaintsLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_LOADING",
    payload: {
      loading: bool
    }
  });
}
function* setSummaryLoading(bool) {
  yield put({
    type: "SET_SUMMARY_LOADING",
    payload: {
      loading: bool
    }
  });
}

function* setComplaintsPaginationLoading(bool) {
  yield put({
    type: "SET_COMPLAINTS_PAGINATION_LOADING",
    payload: {
      loading: bool
    }
  });
}

// function* setComplaintsAssetsLoading(bool) {
//   yield put({
//     type: "SET_COMPLAINTS_ASSETS_LOADING",
//     payload: {
//       assetsLoading: bool,
//     },
//   });
// }

function* uploadFiles(files, complaintId, locationId, folder) {
  const uploadPromises = files.map((file) =>
    uploadCompliantProof(file, complaintId, locationId, folder)
  );
  return yield Promise.all(uploadPromises);
}
