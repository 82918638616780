import LogRocket from "logrocket";
import React, { useEffect, useMemo, useState } from "react";
import {
  useCurrentRefinements,
  useHits,
  useSearchBox
} from "react-instantsearch";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import {
  CloseIcon,
  ComplaintsIcon,
  DashboardIcon,
  LogoutIcon,
  MapIcon,
  ProceduresIcon,
  UserIcon
} from "./Assets/assets";
import chennaiCorporationLogo from "./Assets/chennaiCorporationLogo.png";
import Button from "./Components/Button/Button";
import Header from "./Components/Header/Header";
import Modal from "./Components/Modal/Modal";
import SnackBar from "./Components/SnackBar/SnackBar";
import { clearAlgolia, putAlgoliaData } from "./Redux/algolia/action";
import { logout } from "./Redux/authentication/actions";
import { getTotalComplaints } from "./Redux/complaints/action";
import { setLocationSummaryData } from "./Redux/locations/action";
import {
  setActiveSwitch,
  setSelectedTable,
  setShowModal,
  setTableFilter,
  setTableSearchKey
} from "./Redux/table/action";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import Procedures from "./Screens/Procedures/Procedures";
import Profile from "./Screens/Profile/Profile";
import SetPassword from "./Screens/SetPassword/SetPassword";
import Summary from "./Screens/Summary/Summary";
import AddDevice from "./Screens/Surveillance/AddDevice";
import FaceComparison from "./Screens/Surveillance/faceComparison";
import Surveillance from "./Screens/Surveillance/Surveillance";
import Test from "./Screens/Test/Test";
import { useAuthSubscriber } from "./Services/authentication";
import {
  useCaptureSummaryListener,
  useDevicesListener,
  useEmployeesListener,
  useLocationsListener,
  usePicturesListener,
  useProcedureHistoryListener,
  useProceduresListener,
  useSensorsListener,
  useTracksListener
} from "./Services/database";
import useComplaints from "./Services/useComplaints.js";
import { isValidArray, isValidObject } from "./Services/validators";
import {
  calculateTotal,
  calculateTotalPercentage,
  defaultFilterData,
  getCaptureSummaryPercentage,
  getComplaintsCount,
  getCompletedProcedureCount,
  getLastManPowerCaptured,
  getMenuTitle,
  getStatusCount,
  getValidStatusByCount,
  logRocketInit,
  proceduresHistoryQueryPath,
  tableTitles,
  trackQueryPath,
  transformTableName
} from "./Utils/constants";

function App(props) {
  const navigate = useNavigate();
  const { items } = useCurrentRefinements();
  const { hits } = useHits();
  const { refine } = useSearchBox();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(null);
  const [enableSearch, setEnableSearch] = useState(false);
  const [enableMenu, setEnableMenu] = useState(false);
  const [enableFilter, setEnableFilter] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [locationData, setLocationData] = useState(null);
  const [claims, setClaims] = useState(null);
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [tableSearchKey, setTableSearchKey] = useState({
    complaints: props.table.complaints.searchKey || "",
    workForce: "",
    procedures: ""
  });

  useEffect(() => {
    if (
      props.table.locations?.filter?.dateRange?.from === null &&
      props.table.locations?.filter?.dateRange?.to === null
    ) {
      props.setTableFilter(tableTitles.locations, {
        ...props.table.locations.filter,
        dateRange: {
          from: new Date().toISOString().split("T")[0],
          to: new Date().toISOString().split("T")[0]
        }
      });
    }
    // eslint-disable-next-line
  }, [props.table.locations?.filter]);

  useEffect(() => {
    if (props.table.workForce?.filter?.date === null) {
      props.setTableFilter(tableTitles.workForce, {
        ...props.table.workForce.filter,
        date: new Date().toISOString().split("T")[0]
      });
    }
    // eslint-disable-next-line
  }, [props.table.workForce?.filter]);

  const menu = [
    {
      title: "Dashboard",
      icon: <DashboardIcon color={"white"} />
    },
    {
      title: "Presets",
      icon: <ProceduresIcon color={"white"} />
    }
  ];

  useAuthSubscriber((props) => {
    setIsAuth(props.isAuth);
    setClaims(props.claims);
  }, isAuth);

  useProceduresListener({
    isAuth: isAuth
  });

  useCaptureSummaryListener({
    isAuth: isAuth
  });

  useLocationsListener({
    isAuth: isAuth
  });

  useSensorsListener({ isAuth: isAuth });

  useEmployeesListener({ isAuth: isAuth });

  usePicturesListener({
    isAuth: isAuth,
    isAllowedToRead: claims?.surveillance?.read
  });

  useDevicesListener({ isAuth: isAuth });

  const isAllowed = () => {
    if (
      claims?.employee?.create &&
      claims?.employee?.update &&
      claims?.procedure?.create &&
      claims?.procedure?.update
    ) {
      return true;
    } else {
      return false;
    }
  };
  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      if (logRocketInit.key) {
        LogRocket.init(logRocketInit.key);
      }
    }
  }, []);

  // Generate query for procedures filter
  const procedureHistoryFilterQuery = useMemo(() => {
    let query = [];
    const currentFilter = props.table.procedures.filter;
    if (isValidObject(currentFilter)) {
      Object.keys(currentFilter).forEach((key) => {
        if (key !== "dateRange" && isValidArray(currentFilter[key])) {
          if (currentFilter?.[key]) {
            query = [
              ...query,
              proceduresHistoryQueryPath[key],
              "in",
              currentFilter?.[key]
            ];
          }
        } else if (key === "dateRange") {
          if (currentFilter[key].from) {
            query = [
              ...query,
              proceduresHistoryQueryPath[key],
              ">=",
              +new Date(`${currentFilter?.[key]?.from}:00:00:00`)
            ];
          }
          if (currentFilter[key].to) {
            query = [
              ...query,
              proceduresHistoryQueryPath[key],
              "<=",
              +new Date(`${currentFilter?.[key]?.to}:23:59:59`)
            ];
          }
        }
      });
    }
    return query;
  }, [props.table.procedures.filter]);

  // Generate query for procedures filter
  const workForceFilterQuery = useMemo(() => {
    let query = [];

    const currentFilter = props.table.workForce.filter;
    if (isValidObject(currentFilter)) {
      Object.keys(currentFilter).forEach((key) => {
        if (key === "date") {
          query = [
            ...query,
            trackQueryPath[key],
            ">=",
            +new Date(`${currentFilter?.[key]}:00:00:00`),
            trackQueryPath[key],
            "<=",
            +new Date(`${currentFilter?.[key]}:23:59:59`)
          ];
        }
      });
    }
    return query;
  }, [props.table.workForce.filter]);

  const [
    getComplaintsNextPage,
    getComplaintsAssets,
    complaintsLoading,
    downloadComplaintsReport,
    handleComplaintSort,
    complaintsSortConfig
  ] = useComplaints({
    isAuth: isAuth,
    filterData: props.table.complaints.filter,
    searchKey: props.table.complaints.searchKey,
    locationData: props.locations.data,
    accessToken: props.auth?.data.accessToken,
    selectedComplaint: props.table.complaints.selectedComplaint
  });

  const [
    getProcedureHistoryNextPage,
    getProcedureHistoryAssets,
    proceduresAssetsLoading
  ] = useProcedureHistoryListener(isAuth, procedureHistoryFilterQuery);

  useTracksListener({ isAuth: isAuth, filters: workForceFilterQuery });

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  useEffect(() => {
    refine(props.algolia.data?.query);
    // eslint-disable-next-line
  }, [props.algolia.data.query]);

  useEffect(() => {
    if (window.location.pathname === "/login") {
      setEnableMenu(false);
      setEnableFilter(false);
      setLogoutModal(false);
      return;
    }

    // eslint-disable-next-line
  }, [window.location.pathname]);

  //to show and hide searchbar box
  useEffect(() => {
    if (window.location.pathname !== "/" && showSearchBox) {
      return setShowSearchBox(false);
    }

    if (window.location.pathname === "/" && !showSearchBox) {
      return setShowSearchBox(true);
    }

    // eslint-disable-next-line
  }, [window.location.pathname, props.table.selectedTable]);

  useEffect(() => {
    if (
      !window.location.pathname.includes("presets") &&
      props.algolia.data.from === "procedure"
    ) {
      props.putAlgoliaData({
        query: "",
        from: null
      });
    }
    // eslint-disable-next-line
  }, [props.algolia.data, window.location.pathname]);

  const isFilterApplied = (selectedFilter, table) => {
    if (table === tableTitles.locations) {
      if (
        selectedFilter?.status?.length > 0 ||
        selectedFilter?.zone?.length > 0 ||
        selectedFilter?.ward?.length > 0 ||
        selectedFilter?.category?.length > 0 ||
        (selectedFilter?.dateRange?.from &&
          selectedFilter?.dateRange?.to &&
          selectedFilter?.dateRange?.from !==
            new Date().toISOString().split("T")[0])
      ) {
        return { validation: true, show: true };
      } else {
        return { validation: false, show: true };
      }
    }

    if (table === tableTitles.complaints) {
      if (
        selectedFilter?.status?.length > 0 ||
        selectedFilter?.category?.length > 0 ||
        selectedFilter?.zone?.length > 0 ||
        selectedFilter?.ward?.length > 0 ||
        selectedFilter?.issueTakenBy?.length > 0 ||
        selectedFilter?.issueRaised?.length > 0 ||
        selectedFilter?.PCTNo?.length > 0 ||
        selectedFilter?.zone?.length > 0 ||
        selectedFilter?.ward?.length > 0 ||
        (selectedFilter?.dateRange?.from && selectedFilter?.dateRange?.to)
      ) {
        return { validation: true, show: true };
      } else {
        return { validation: false, show: false };
      }
    }

    if (table === tableTitles.procedures) {
      if (
        selectedFilter?.procedureName?.length > 0 ||
        selectedFilter?.assignedTo?.length > 0 ||
        selectedFilter?.procedureType?.length > 0 ||
        (selectedFilter?.dateRange?.from && selectedFilter?.dateRange?.to)
      ) {
        return { validation: true, show: true };
      } else {
        return { validation: false, show: false };
      }
    }

    if (table === tableTitles.workForce) {
      if (selectedFilter?.date) {
        return { validation: true, show: true };
      } else {
        return { validation: false, show: false };
      }
    }
  };

  useEffect(() => {
    if (isValidObject(props.locations.data)) {
      let updatedData;
      const filterData = [...Object.values(props.locations.data)];
      const searchData = hits?.map((loc) => loc?.objectID);
      let lastManpowerCapturedArray = [];
      let manPowerPercentageArray = [];

      const today = new Date().toISOString().split("T")[0];
      const locationFilter = props.table.locations.filter;
      if (
        isFilterApplied(locationFilter, "LOCATIONS").validation ||
        props.algolia.data.query?.length > 0
      ) {
        updatedData = filterData?.filter((item) => {
          //search filter
          if (
            props.algolia.data.query?.length > 0 &&
            !searchData?.includes(item.documentId)
          ) {
            return false;
          }
          // Category filter
          if (
            locationFilter?.category?.length > 0 &&
            !locationFilter?.category.includes(item.category)
          ) {
            return false;
          }

          // Zone filter
          if (
            locationFilter?.zone?.length > 0 &&
            !locationFilter?.zone.includes(item.zone)
          ) {
            return false;
          }

          // Ward filter
          if (
            locationFilter?.ward?.length > 0 &&
            !locationFilter?.ward.includes(item.ward)
          ) {
            return false;
          }

          // Status filter
          if (
            locationFilter?.status?.length > 0 &&
            !locationFilter?.status.includes(item.status)
          ) {
            return false;
          }
          // Date  filter
          if (
            locationFilter &&
            ((locationFilter?.dateRange?.from === null &&
              locationFilter?.dateRange?.to === null) ||
              locationFilter?.dateRange?.from !== today ||
              locationFilter?.dateRange?.to !== today)
          ) {
            const { documentId, complaintCount, procedureCount } = item;
            const captureSummary =
              documentId && props.employees.captureSummary?.[documentId];

            item.totalManPower = captureSummary
              ? calculateTotalPercentage(
                  captureSummary,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "dayCount"
                )
              : "N/A";

            manPowerPercentageArray.push(item.totalManPower);

            item.totalManPowerCapacity = captureSummary
              ? getLastManPowerCaptured(captureSummary)
              : "N/A";

            lastManpowerCapturedArray.push(item.totalManPowerCapacity ? 1 : 0);

            item.totalOpenCompliant = complaintCount
              ? calculateTotal(
                  item,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "complaintCount",
                  "open"
                )
              : 0;
            item.totalClosedCompliant = complaintCount
              ? calculateTotal(
                  item,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "complaintCount",
                  "closed"
                )
              : 0;

            item.totalUnderReviewCompliant = complaintCount
              ? calculateTotal(
                  item,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "complaintCount",
                  "underReview"
                )
              : 0;

            item.totalPositiveProcedure =
              procedureCount &&
              locationFilter?.dateRange?.from !== locationFilter?.dateRange?.to
                ? calculateTotal(
                    item,
                    locationFilter?.dateRange?.from,
                    locationFilter?.dateRange?.to,
                    "procedureCount",
                    "positive"
                  )
                : 0;
            item.totalNegativeProcedure = procedureCount
              ? calculateTotal(
                  item,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "procedureCount",
                  "negative"
                )
              : 0;
            item.totalCompletedProcedure =
              procedureCount &&
              calculateTotal(
                item,
                locationFilter?.dateRange?.from,
                locationFilter?.dateRange?.to,
                "procedureCount",
                "positive"
              ) +
                calculateTotal(
                  item,
                  locationFilter?.dateRange?.from,
                  locationFilter?.dateRange?.to,
                  "procedureCount",
                  "negative"
                );

            item.status = getValidStatusByCount(
              item?.complaintCount
                ? calculateTotal(
                    item,
                    locationFilter?.dateRange?.from,
                    locationFilter?.dateRange?.to,
                    "complaintCount",
                    "open"
                  ) -
                    calculateTotal(
                      item,
                      locationFilter?.dateRange?.from,
                      locationFilter?.dateRange?.to,
                      "complaintCount",
                      "closed"
                    )
                : 0
            );
          }

          return true;
        });
      } else {
        updatedData = filterData?.map((item) => {
          const { documentId, complaintCount, procedureCount } = item;
          const captureSummary =
            documentId && props.employees.captureSummary?.[documentId];
          const totalManPowerCapacity = captureSummary
            ? getLastManPowerCaptured(captureSummary)
            : "N/A";

          lastManpowerCapturedArray.push(totalManPowerCapacity ? 1 : 0);

          const totalManPower = captureSummary
            ? getCaptureSummaryPercentage(captureSummary)
            : "N/A";

          manPowerPercentageArray.push(totalManPower);

          const totalOpenCompliant = complaintCount
            ? getComplaintsCount(item, "open")
            : 0;

          const totalClosedCompliant = complaintCount
            ? getComplaintsCount(item, "closed")
            : 0;

          const totalUnderReviewCompliant = complaintCount
            ? getComplaintsCount(item, "underReview")
            : 0;

          const totalPositiveProcedure = procedureCount
            ? getCompletedProcedureCount(item)
            : 0;
          const totalNegativeProcedure = procedureCount
            ? getCompletedProcedureCount(item)
            : 0;
          const totalCompletedProcedure = procedureCount
            ? getCompletedProcedureCount(item)
            : 0;

          const status = getValidStatusByCount(getStatusCount(item));

          return {
            ...item,
            totalManPowerCapacity,
            totalManPower,
            totalOpenCompliant,
            totalClosedCompliant,
            totalUnderReviewCompliant,
            totalPositiveProcedure,
            totalNegativeProcedure,
            totalCompletedProcedure,
            status
          };
        });
      }

      setLocationData(updatedData);

      const _manPowerTotalPercentage = manPowerPercentageArray.filter(
        (value) => value !== "N/A"
      );
      const manPowerAverage =
        _manPowerTotalPercentage.reduce((a, b) => a + b, 0) /
          _manPowerTotalPercentage.length || 0;

      const _lastManpowerCapturePercentage = lastManpowerCapturedArray.filter(
        (value) => value !== "N/A"
      );
      const lastManpowerCapturedAverage =
        _lastManpowerCapturePercentage.reduce((a, b) => a + b, 0) /
          _lastManpowerCapturePercentage.length || 0;

      props.setLocationSummaryData(
        manPowerAverage.toFixed(2),
        lastManpowerCapturedAverage.toFixed(2)
      );
    }
    // eslint-disable-next-line
  }, [
    props.locations.data,
    props.algolia.data.query,
    hits,
    props.table.locations.filter,
    props.employees.captureSummary
  ]);

  const navigation = [
    {
      title: tableTitles.locations,
      icon: <MapIcon width="20" height="20" color="white" />
    },
    // {
    //   title: tableTitles.preset,
    //   selected: false,
    //   icon: (
    //     <PresetIcon
    //       width="19"
    //       height="19"
    //       color={props.theme === "dark" ? "white" : "#404040"}
    //     />
    //   ),
    // },
    {
      title: tableTitles.complaints,
      icon: <ComplaintsIcon width="20" height="20" color="white" />
    },

    {
      title: tableTitles.workForce,
      icon: <UserIcon width="16" height="16" color="white" />
    },
    {
      title: tableTitles.procedures,
      icon: <ProceduresIcon color="white" />
    }
  ];

  return (
    <div
      className={`font-family-aldrich-regular font-color-white background-color-dark inherit-parent-height inherit-parent-width `}
    >
      {isAuth === null ? (
        <div data-cy="home-loading"></div>
      ) : (
        <>
          {(window.location.pathname !== "/login" ||
            window.location.pathname !== "/setPassword") && (
            <Header
              theme={"dark"}
              activeSwitch={props.table.activeSwitch}
              setActiveSwitch={props.setActiveSwitch}
              showSearchBox={showSearchBox}
              enableFilter={enableFilter}
              setEnableFilter={setEnableFilter}
              searchBoxPlaceHolder={"Search for something..."}
              filterClick={() => {
                if (enableFilter) {
                  setEnableFilter(false);
                } else {
                  setEnableFilter(true);
                  props.setActiveSwitch("TABLE");
                }
              }}
              searchClick={() => {
                setEnableSearch(true);
                setEnableFilter(false);
                setEnableMenu(false);
              }}
              menuClick={() => {
                setEnableMenu(true);
                setEnableSearch(false);
                setEnableFilter(false);
              }}
              enableSearch={enableSearch}
              searchKeyOnChange={(event) => {
                switch (props.table.selectedTable) {
                  case tableTitles.locations:
                    props.putAlgoliaData({
                      query: event.target.value,
                      from: "header"
                    });
                    break;

                  case tableTitles.complaints:
                    setTableSearchKey({
                      ...tableSearchKey,
                      complaints: event.target.value.toUpperCase()
                    });
                    // props.setTableSearchKey(
                    //   tableTitles.complaints,
                    //   event.target.value
                    // );
                    break;

                  default:
                    break;
                }
              }}
              searchKey={() => {
                switch (props.table.selectedTable) {
                  case tableTitles.locations:
                    if (props.algolia.data.from === "procedure") {
                      return "";
                    } else {
                      return props.algolia.data.query;
                    }

                  case tableTitles.complaints:
                    return tableSearchKey.complaints;

                  case tableTitles.workForce:
                    return tableSearchKey.workForce || "";

                  case tableTitles.procedures:
                    return tableSearchKey.procedures || "";

                  default:
                    break;
                }
              }}
              searchLoading={complaintsLoading.search}
              showEnterButton={
                tableSearchKey.complaints.length === 8 &&
                tableSearchKey.complaints !== props.table.complaints.searchKey
                  ? true
                  : false
              }
              // disableEnterButton = {  }
              enterButtonOnclick={() => {
                switch (props.table.selectedTable) {
                  case tableTitles.complaints:
                    props.setTableSearchKey(
                      tableTitles.complaints,
                      tableSearchKey.complaints
                    );
                    if (props.table.showModal.summary !== null) {
                      props.setShowModal({
                        summary: null
                      });
                    }
                    break;

                  default:
                    break;
                }
              }}
              closeButtonOnClick={() => {
                switch (props.table.selectedTable) {
                  case tableTitles.locations:
                    if (props.algolia.data.query.length > 0) {
                      props.putAlgoliaData({
                        query: "",
                        from: null
                      });
                    } else {
                      props.setActiveSwitch("MAP");
                    }
                    break;

                  case tableTitles.complaints:
                    if (
                      props.table.complaints?.searchKey?.length > 0 ||
                      tableSearchKey.complaints.length > 0
                    ) {
                      setTableSearchKey({ ...tableSearchKey, complaints: "" });
                      props.setTableSearchKey(tableTitles.complaints, "");
                    } else {
                      props.setActiveSwitch("MAP");
                    }
                    break;

                  case tableTitles.workForce:
                  case tableTitles.procedures:
                    props.setActiveSwitch("MAP");
                    break;

                  default:
                    break;
                }
              }}
              clearAll={() => {
                props.setTableFilter(
                  props.table.selectedTable,
                  defaultFilterData[
                    transformTableName(props.table.selectedTable)
                  ]
                );
                if (props.table.selectedTable === tableTitles.locations) {
                  props.clearAlgolia();
                }
              }}
              items={items}
              isFilterApplied={isFilterApplied}
              table={props.table}
              setTableFilter={props.setTableFilter}
              employeesData={props.employees.data}
              proceduresData={props.procedures.data}
              overLayClick={() => {
                setEnableSearch(false);
              }}
            />
          )}

          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute isAuth={isAuth}>
                  <Login navigate={navigate} theme={"dark"} />
                </PublicRoute>
              }
            />
            <Route
              path="/setPassword"
              element={
                <PublicRoute isAuth={isAuth}>
                  <SetPassword navigate={navigate} theme={"dark"} />
                </PublicRoute>
              }
            />

            <Route
              path="/test"
              element={<Test navigate={navigate} theme={"dark"} />}
            />

            <Route
              path="/"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Home
                    isAuth={isAuth}
                    claims={claims}
                    downloadComplaintsReport={downloadComplaintsReport}
                    complaintsExportLoading={complaintsLoading.export}
                    proceduresFilterQuery={procedureHistoryFilterQuery}
                    activeSwitch={props.table.activeSwitch}
                    setActiveSwitch={props.setActiveSwitch}
                    showLoginModal={window.location.pathname === "/login"}
                    enableSearch={enableSearch}
                    searchKey={props.algolia.data.query}
                    navigate={navigate}
                    hits={locationData}
                    isFilterApplied={
                      isFilterApplied(
                        props.table[
                          transformTableName(props.table.selectedTable)
                        ]?.filter,
                        props.table.selectedTable,
                        "header"
                      )?.validation ||
                      isFilterApplied(
                        props.table[
                          transformTableName(props.table.selectedTable)
                        ]?.filter,
                        props.table.selectedTable,
                        "header"
                      )?.show
                    }
                    enableFilter={enableFilter}
                    items={items.map((data) => data.refinements)}
                    getComplaintsNextPage={() => {
                      getComplaintsNextPage();
                    }}
                    getComplaintsAssets={getComplaintsAssets}
                    complaintsLoading={complaintsLoading}
                    handleComplaintSort={handleComplaintSort}
                    complaintsSortConfig={complaintsSortConfig}
                    getProcedureHistoryNextPage={getProcedureHistoryNextPage}
                    getProcedureHistoryAssets={getProcedureHistoryAssets}
                    proceduresAssetsLoading={proceduresAssetsLoading}
                    setEnableFilter={setEnableFilter}
                    theme={"dark"}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="/summary/:summaryType"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Summary isAuth={isAuth} navigate={navigate} theme={"dark"} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/presets"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  {isAllowed() ? (
                    <Procedures
                      show={
                        enableSearch && props.algolia.data.query.length >= 1
                      }
                      hits={locationData?.filter(
                        (data) => data.access === "public"
                      )}
                      algoliaSearchKey={(data, clear) => {
                        if (clear) {
                          props.putAlgoliaData({
                            query: props.algolia.data.query,
                            from: "header"
                          });
                        } else {
                          props.putAlgoliaData({
                            query: data,
                            from: "procedure"
                          });
                        }
                      }}
                      removeAlgoliaSearch={() => {
                        props.putAlgoliaData({
                          query: "",
                          from: null
                        });
                      }}
                      navigate={navigate}
                      theme={"dark"}
                    />
                  ) : (
                    <Navigate to="/" />
                  )}
                </ProtectedRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Profile navigate={navigate} />
                </ProtectedRoute>
              }
            />

            {/* <Route
              path="/test"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Test navigate={navigate} />
                </ProtectedRoute>
              }
            /> */}

            <Route
              path="/surveillance"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Surveillance navigate={navigate} theme={props.auth.theme} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/surveillance/addDevice"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <AddDevice navigate={navigate} theme={props.auth.theme} />
                </ProtectedRoute>
              }
            />

            <Route
              path="/surveillance/captures"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <FaceComparison
                    navigate={navigate}
                    theme={props.auth.theme}
                  />
                </ProtectedRoute>
              }
            />

            <Route
              path="*"
              element={
                <ProtectedRoute isAuth={isAuth}>
                  <Navigate to="/" replace />
                </ProtectedRoute>
              }
            />
          </Routes>

          {/* Navigation Bar */}
          {(window.location.pathname === "/" ||
            window.location.pathname === "/filters") && (
            <div
              style={{ height: "58px" }}
              className={` ${
                props.table.activeSwitch === "MAP"
                  ? "bottom-navigation-background-color-dark"
                  : "background-color-dark"
              }  position-absolute bottom-0 inherit-parent-width display-flex flex-justify-content-center flex-align-items-center `}
              data-cy="bottom-navigation-bar"
            >
              {navigation.map((data, index) => (
                <div
                  key={index}
                  style={{ height: "40px", width: "175px" }}
                  className={` ${
                    data.title === props.table.selectedTable
                      ? "background-gradient-light"
                      : ""
                  } ${
                    index === navigation.length - 1
                      ? "border-bottom-1px-e5e5e5"
                      : `navigation-border-dark`
                  }   display-flex flex-align-items-center flex-justify-content-center padding-default cursor-pointer`}
                  onClick={() => {
                    props.setSelectedTable(data.title);
                    if (data.title === "WORKFORCE" && enableFilter) {
                      setEnableFilter(false);
                    }
                    if (props.table.showModal.summary !== null) {
                      if (
                        data.title === "WORKFORCE" ||
                        data.title === "PROCEDURES"
                      ) {
                        props.setShowModal({ summary: null });
                      } else {
                        props.setShowModal({ summary: data.title });
                      }
                    }
                  }}
                  data-cy={`${data.title}-navigation-button`}
                >
                  {data.icon}
                  <div className=" padding-left-default font-size-small">
                    {data.title}
                  </div>
                </div>
              ))}
            </div>
          )}

          <Modal
            show={enableMenu && window.location.pathname !== "/login"}
            canIgnore={true}
            onClose={() => {
              setEnableMenu(!enableMenu);
            }}
            width="width-fit-content"
            boxShadow="none"
            // maxWidth="max-width-528px"
            background="background-transparent"
            height=" inherit-parent-height"
            position={" position-absolute bottom-0 "}
          >
            <div
              style={{ width: "230px" }}
              className={`background-color-dark inherit-parent-height  font-family-gilroy-medium  background-white `}
              data-cy="logout-confirmation-modal"
            >
              <div className="display-flex flex-align-items-center padding-left-larger padding-top-default padding-bottom-default">
                <div
                  onClick={() => {
                    setEnableMenu(false);
                  }}
                  className="cursor-pointer"
                  data-cy={"jam-menu-icon"}
                >
                  <CloseIcon color="white" width={16} height={16} />
                </div>
                <div className="cursor-pointer margin-left-larger margin-right-larger">
                  <img
                    src={chennaiCorporationLogo}
                    alt="chennai-corporation-logo"
                    width={32}
                  />
                </div>
                <div className="font-size-18px cursor-pointer padding-right-larger  margin-right-larger">
                  GCC
                </div>
              </div>
              <div className=" remaining-body-height-from-header flex-direction-column flex-justify-content-space-between ">
                <div>
                  {menu.map((menu, index) => (
                    <div
                      key={index}
                      style={{ width: "214px" }}
                      className={`${
                        getMenuTitle(window.location.pathname) === menu.title
                          ? "background-color-primary font-color-white"
                          : ""
                      } display-flex flex-align-items-center border-radius-right-4px padding-top-default padding-bottom-default padding-left-larger margin-top-medium margin-bottom-medium width-fit-content cursor-pointer ${
                        menu.title === "Presets"
                          ? isAllowed()
                            ? ""
                            : "font-color-tertiary cursor-no-drop"
                          : ""
                      }`}
                      data-cy={`menu-${menu.title}${
                        props.selectedMenu === menu.title ? "-selected" : ""
                      }`}
                      onClick={() => {
                        if (
                          (menu.title === "Presets" && isAllowed()) ||
                          menu.title !== "Presets"
                        ) {
                          setEnableMenu(false);
                          switch (menu.title) {
                            case "Dashboard":
                              return navigate("/");
                            case "Admin":
                              return navigate("/");
                            case "Presets":
                              return navigate("/presets");
                            case "Manager":
                              return navigate("/");
                            default:
                              break;
                          }
                        }
                      }}
                    >
                      {menu.icon}
                      <div className=" padding-left-default">{menu.title}</div>
                    </div>
                  ))}
                </div>

                <div className=" padding-bottom-larger ">
                  <div
                    style={{ width: "214px" }}
                    className="  cursor-pointer border-radius-right-4px padding-top-default padding-bottom-default padding-left-larger margin-top-medium margin-bottom-medium display-flex flex-align-items-center"
                    onClick={() => {
                      setEnableMenu(false);
                      setLogoutModal(true);
                    }}
                    data-cy={"menu-Logout"}
                  >
                    <LogoutIcon color="white" />
                    <div className=" padding-left-default">Logout</div>
                  </div>
                  <div className="padding-left-larger font-size-smaller">
                    &copy; ALL RIGHTS RESERVED 2024
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            show={logoutModal}
            canIgnore={true}
            onClose={() => {
              setLogoutModal(!logoutModal);
            }}
            background="background-transparent"
            width="inherit-parent-width"
            boxShadow="box-shadow-none"
            maxWidth="max-width-528px"
            height="height-fit-to-content"
          >
            <div
              className={`background-color-dark font-family-gilroy-medium text-align-center padding-larger background-white border-radius-default `}
              data-cy="logout-confirmation-modal"
            >
              <div className=" font-size-default">LOGOUT</div>
              <div className="font-size-default padding-top-medium">
                Are you sure you want to logout?
              </div>

              <div className="display-flex padding-top-larger">
                <Button
                  boxShadow={false}
                  type="submit"
                  text="Logout"
                  className={" margin-right-medium"}
                  onClick={() => {
                    setEnableMenu(false);
                    logout();
                    setLogoutModal(false);
                  }}
                  data-cy="confirm-logout-button"
                />
                <Button
                  boxShadow={false}
                  text="Cancel"
                  variant="secondary"
                  onClick={() => {
                    setLogoutModal(false);
                  }}
                  className={`font-color-white border-1px-e5e5e5 margin-left-medium`}
                  data-cy="cancel-logout-button"
                />
              </div>
            </div>
          </Modal>
          <SnackBar
            message={status}
            status={props.status}
            type={props.status.code === null ? "success" : "error"}
            theme={"dark"}
          />
        </>
      )}
    </div>
  );
}

const mapStateToProps = function(state) {
  return {
    auth: state.auth,
    algolia: state.algolia,
    locations: state.locations,
    status: state.status,
    table: state.table,
    procedures: state.procedures,
    employees: state.employees,
    complaints: state.complaints
  };
};

const mapDispatchToProps = function() {
  return {
    putAlgoliaData: (data) => putAlgoliaData(data),
    clearAlgolia: (data) => clearAlgolia(data),
    getTotalComplaints: (data) => getTotalComplaints(data),
    setTableFilter: (table, filter) => setTableFilter(table, filter),
    setSelectedTable: (table) => setSelectedTable(table),
    setLocationSummaryData: (manPowerAverage, lastManpowerCapturedAverage) =>
      setLocationSummaryData(manPowerAverage, lastManpowerCapturedAverage),
    setActiveSwitch: (value) => setActiveSwitch(value),
    setShowModal: (data) => setShowModal(data),
    setTableSearchKey: (tableName, searchKey) =>
      setTableSearchKey(tableName, searchKey)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ isAuth, children }) => {
  if (!isAuth) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};
