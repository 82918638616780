import React, { useState, useEffect } from "react";
import "./Header.css";
import {
  CloseIcon,
  EnterArrowIcon,
  FilterIcon,
  JamMenuIcon,
  SearchIcon,
  // ThemeIcon,
} from "../../Assets/assets";
import {
  transformKey,
  transformTableName,
  transformValue,
} from "../../Utils/constants";
import { useHorizontalScroll } from "../../Services/useHorizontalScroll";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";
import { complaintsCategory } from "../Filter/constants";
import { isValidArray, isValidObject } from "../../Services/validators";

export default function Header(props) {
  return (
    <div
      className={` ${
        props.theme === "dark" ? "background-color-dark" : "background-white"
      } `}
    >
      <div
        className={`height-64px ${props.className} inherit-parent-width display-flex flex-align-items-center padding-top-large padding-bottom-large `}
        data-cy="header"
      >
        <div className="inherit-parent-width">
          <div className="display-flex inherit-parent-width flex-align-items-center flex-justify-content-space-between">
            <div className="display-flex padding-left-larger flex-align-items-center">
              <div
                onClick={props.menuClick}
                className="cursor-pointer"
                data-cy="jam-menu-icon"
              >
                <JamMenuIcon
                  color={props.theme === "dark" ? "white" : "#404040"}
                />
              </div>
              <div className="cursor-pointer margin-left-larger margin-right-larger">
                <img
                  src={chennaiCorporationLogo}
                  alt="chennai-corporation-logo"
                  width={32}
                />
              </div>
              <div className="font-size-18px  cursor-pointer padding-right-larger  margin-right-larger">
                GCC
              </div>
            </div>
            <div className="display-flex flex-align-items-center">
              {props?.showSearchBox && (
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                  }}
                  style={{ height: 36 }}
                  className={`inherit-parent-width margin-left-larger margin-right-larger `}
                >
                  <div className="display-flex flex-justify-content-center flex-align-items-center  border-bottom-tertiary-dark ">
                    <input
                      style={{
                        width: "304px",
                      }}
                      className={` background-color-dark font-color-white inherit-parent-height border-none font-size-medium padding-default inherit-parent-width font-family-aldrich-regular`}
                      value={props.searchKey()}
                      onChange={props.searchKeyOnChange}
                      placeholder={props.searchBoxPlaceHolder}
                      title="Search bar"
                      data-cy="search-input-field"
                      onFocus={() => {
                        if (props.enableFilter) {
                          props.setEnableFilter(false);
                        }
                        if (props?.activeSwitch === "MAP") {
                          props.setActiveSwitch("TABLE");
                        }
                      }}
                    />
                    <button
                      className="padding-right-default background-color-transparent cursor-pointer"
                      type={"button"}
                      data-cy={
                        props?.activeSwitch === "TABLE"
                          ? "search-clear-button"
                          : "search-button"
                      }
                      onClick={() => {
                        if (props.showEnterButton) {
                          props.enterButtonOnclick();
                        } else if (props?.activeSwitch === "TABLE") {
                          props.closeButtonOnClick();
                          if (props.enableFilter) {
                            props.setEnableFilter(false);
                          }
                        }
                      }}
                    >
                      {props?.activeSwitch === "MAP" &&
                      !props.searchLoading &&
                      !props.showEnterButton ? (
                        <SearchIcon
                          color={props.theme === "dark" ? "white" : "#404040"}
                        />
                      ) : (
                        <></>
                      )}

                      {props?.activeSwitch === "TABLE" &&
                      !props.searchLoading &&
                      !props.showEnterButton ? (
                        <CloseIcon color={"white"} width={16} height={16} />
                      ) : (
                        <></>
                      )}

                      {props.showEnterButton && !props.searchLoading ? (
                        <EnterArrowIcon width={16} height={16} />
                      ) : (
                        <></>
                      )}

                      {props.searchLoading ? (
                        <div
                          className="export-loader-dark "
                          style={{ height: "16px", width: "16px" }}
                        />
                      ) : (
                        <></>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </div>
            <div style={{ width: "210px" }}>
              <div className="display-flex flex-justify-content-center">
                {/* <div
                  onClick={props.themeOnClick}
                  className="cursor-pointer margin-top-larger margin-bottom-larger margin-left-larger"
                  data-cy="theme-icon"
                >
                  <ThemeIcon
                    color={props.theme === "dark" ? "white" : "#404040"}
                  />
                </div> */}

                {/* {props?.showFilterIcon && ( */}
                <div
                  onClick={props.filterClick}
                  className="cursor-pointer margin-larger"
                  data-cy="filter-icon"
                >
                  <FilterIcon
                    color={props.theme === "dark" ? "white" : "#404040"}
                  />
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(props.isFilterApplied(
        props.table[transformTableName(props.table.selectedTable)]?.filter,
        props.table.selectedTable,
        "header"
      )?.validation ||
        props.isFilterApplied(
          props.table[transformTableName(props.table.selectedTable)]?.filter,
          props.table.selectedTable,
          "header"
        )?.show) &&
        !props.enableFilter &&
        window.location.pathname === "/" && (
          <div
            className={`inherit-parent-width font-size-medium flex-center-children-vertically padding-left-larger padding-right-larger padding-bottom-default padding-top-default flex-justify-content-space-between  z-index-1 ${props.filterViewClassName} `}
            data-cy="filter-view"
          >
            <div className="inherit-parent-width-subtract-70px ">
              {
                <div className="inherit-parent-width display-flex flex-justify-content-space-between">
                  <div className=" inherit-parent-width display-flex flex-align-items-center">
                    <FilterView
                      setClearForm={props.setClearForm}
                      items={props.items}
                      filterData={
                        props.table[
                          transformTableName(props.table.selectedTable)
                        ]?.filter
                      }
                      table={props.table}
                      employeesData={props.employeesData}
                      proceduresData={props.proceduresData}
                      setTableFilter={props.setTableFilter}
                      theme={props.theme}
                    />
                  </div>
                </div>
              }
            </div>
            {showClearAllButton(
              props.table.selectedTable,
              props.table.locations,
              props.table
            ) && (
              <div
                onClick={props.clearAll}
                className={` text-underline cursor-pointer`}
                data-cy="clear-all-button"
              >
                Clear All
              </div>
            )}
          </div>
        )}
    </div>
  );
}

const FilterView = (props) => {
  const filterView = useHorizontalScroll();

  const [validFilterData, setValidFilterData] = useState(null);

  useEffect(() => {
    let data = {};
    if (isValidObject(props.filterData)) {
      Object.keys(props.filterData).forEach((key) => {
        if (
          (key !== "dateRange" && isValidArray(props.filterData[key])) ||
          (key === "dateRange" &&
            (props.filterData?.dateRange?.from !== null ||
              props.filterData.dateRange?.to !== null)) ||
          key === "date"
        ) {
          data[key] = props.filterData[key];
        }
      });
      setValidFilterData(data);
    }
  }, [props.filterData]);

  return (
    <>
      <div className="  display-flex padding-right-default">
        {`FILTER`}
        <Spacing /> {"-"} <Spacing />
      </div>

      {
        <div
          ref={filterView}
          className="display-flex flex-align-items-center overflow-scroll hide-scrollbar"
          data-cy="filter-view"
        >
          {isValidObject(validFilterData) &&
            Object.keys(validFilterData)?.map((key, index, filterKeys) => (
              <div className="display-flex" key={index}>
                <div
                  className={`text-capitalize display-flex white-space-nowrap`}
                >
                  {transformKey(key)}
                  <Spacing /> {":"} <Spacing />
                </div>
                {validFilterData[key]?.length > 0 &&
                  key !== "dateRange" &&
                  key !== "date" &&
                  validFilterData[key]?.map((data, count) => (
                    <div
                      className="display-flex  flex-justify-content-center flex-align-items-center"
                      key={count}
                    >
                      <FilterItem
                        type="filter"
                        data={data}
                        count={count}
                        keyName={key}
                        table={props.table}
                        filterData={props.filterData}
                        employeesData={props.employeesData}
                        proceduresData={props.proceduresData}
                        setTableFilter={props.setTableFilter}
                        theme={props.theme}
                      />
                      {filterKeys[index + 1] && (
                        <div className="padding-left-default padding-right-default">
                          |
                        </div>
                      )}
                    </div>
                  ))}
                {key === "date" && validFilterData[key] && (
                  <div className="display-flex flex-justify-content-center flex-align-items-center">
                    <FilterItem
                      keyName={key}
                      table={props.table}
                      data={validFilterData[key]}
                      filterData={props.filterData}
                      setTableFilter={props.setTableFilter}
                      theme={props.theme}
                    />
                    {filterKeys[index + 1] && (
                      <div className="padding-left-default padding-right-default">
                        |
                      </div>
                    )}
                  </div>
                )}
                {key === "dateRange" &&
                  validFilterData[key]?.from &&
                  validFilterData[key]?.to && (
                    <div className="display-flex  flex-justify-content-center flex-align-items-center">
                      <FilterItem
                        keyName={key}
                        table={props.table}
                        data={validFilterData[key]}
                        filterData={props.filterData}
                        setTableFilter={props.setTableFilter}
                        theme={props.theme}
                      />
                      {filterKeys[index + 1] && (
                        <div className="padding-left-default padding-right-default">
                          |
                        </div>
                      )}
                    </div>
                  )}
              </div>
            ))}
        </div>
      }
    </>
  );
};

const Spacing = () => {
  return (
    <p
      className="inherit-parent-height"
      style={{
        width: "8px",
      }}
    />
  );
};

const getValidStatus = (key) => {
  switch (key) {
    case "Optimal":
      return "Green";
    case "Moderate":
      return "Yellow";
    case "Critical":
      return "Red";
    case "UNDER REVIEW":
      return "Under Review";
    case "OPEN":
      return "Open";
    case "CLOSED":
      return "Closed";
    default:
      return;
  }
};

const FilterItem = (props) => {
  const handleRemoveClick = () => {
    const today = new Date().toISOString().split("T")[0];
    const { keyName, filterData, table, setTableFilter, data } = props;
    const { selectedTable } = table;
    const isDateRangeValid =
      filterData.dateRange?.from !== today ||
      filterData.dateRange?.to !== today;

    switch (keyName) {
      case "dateRange":
        if (selectedTable === "LOCATIONS" && isDateRangeValid) {
          setTableFilter(selectedTable, {
            ...filterData,
            [keyName]: { from: null, to: null },
          });
        } else if (selectedTable !== "LOCATIONS") {
          setTableFilter(selectedTable, {
            ...filterData,
            [keyName]: { from: null, to: null },
          });
        }
        break;

      case "date":
        if (selectedTable === "WORKFORCE") {
          setTableFilter(selectedTable, {
            ...filterData,
            date: null,
          });
        }
        break;

      default:
        const filteredCategory = getComplaintCategoryWithoutData(
          data,
          filterData?.[keyName]
        );
        setTableFilter(selectedTable, {
          ...filterData,
          [keyName]: filteredCategory?.found
            ? filteredCategory.withoutData
            : filterData?.[keyName]?.filter((d) => d !== data),
        });
        break;
    }
  };

  function getComplaintCategoryWithoutData(data, filterData) {
    const response = {
      found: false,
      withoutData: [],
    };

    if (!isValidArray(filterData) || !data) {
      return response;
    }

    for (const element of filterData) {
      const complaintCategory = getComplaintCategory(element);

      if (
        typeof complaintCategory?.english === "string" &&
        typeof complaintCategory?.tamil === "string"
      ) {
        response.found = true;
        if (
          complaintCategory?.english !== data &&
          complaintCategory?.tamil !== data
        ) {
          response.withoutData.push(element);
        }
      }
    }

    return response;
  }

  const getComplaintCategory = (data) => {
    for (const subtext of complaintsCategory) {
      if (data === subtext?.english || data === subtext?.tamil) {
        return subtext;
      }
    }
    return undefined;
  };

  const getFilterContentAndCyKey = (type, data) => {
    let content;
    let cyKey;

    switch (type) {
      case "zone":
      case "ward":
      case "category":
      case "issueTakenBy":
      case "issueRaised":
      case "procedureType":
        content = transformValue(data);
        cyKey = `header-${type}-${data}`;
        break;
      case "procedureName":
        content = props.proceduresData[data]?.title;
        cyKey = `header-${type}-${data}`;
        break;
      case "assignedTo":
        content = props.employeesData[data]?.phoneNumber;
        cyKey = `header-${type}-${data}`;
        break;
      case "status":
        content = getValidStatus(data);
        cyKey = `header-status-${getValidStatus(data)}`;
        break;
      case "dateRange":
        content = `${data?.from} - ${data?.to}`;
        cyKey = `header-dateRange-${data?.from} - ${data?.to}`;
        break;
      case "date":
        content = `${data}`;
        cyKey = `header-date-${data}`;
        break;
      case "PCTNo":
        content = `${data}`;
        cyKey = `header-PCTNo-${data}`;
        break;
      default:
        return null;
    }

    return { content, cyKey };
  };

  return (
    <div
      className="display-flex padding-left-medium  text-underline white-space-nowrap"
      data-cy={getFilterContentAndCyKey(props.keyName, props.data)?.cyKey}
      onClick={handleRemoveClick}
      key={props?.key}
    >
      {getFilterContentAndCyKey(props.keyName, props.data)?.content && (
        <div className="text-capitalize display-flex flex-justify-content-center cursor-pointer white-space-nowrap">
          {getFilterContentAndCyKey(props.keyName, props.data)?.content}
        </div>
      )}
      <Spacing />
      <div
        className={`${
          props.count === props.filterData?.[props.keyName]?.length - 1
            ? "padding-right-default"
            : ""
        } cursor-pointer padding-top-smaller padding-left-smaller`}
      >
        <CloseIcon
          height={12}
          width={12}
          color={props.theme === "dark" ? "white" : "#404040"}
        />
      </div>
    </div>
  );
};

const showClearAllButton = (selectedTable, data, table) => {
  if (selectedTable === "LOCATIONS") {
    return (
      isValidArray(data?.filter.category) ||
      isValidArray(data?.filter.status) ||
      isValidArray(data?.filter.zone) ||
      isValidArray(data?.filter.ward) ||
      table[transformTableName(selectedTable)]?.filter?.dateRange?.from !==
        new Date().toISOString().split("T")[0] ||
      table[transformTableName(selectedTable)]?.filter?.dateRange?.to !==
        new Date().toISOString().split("T")[0]
    );
  } else {
    return true;
  }
};
