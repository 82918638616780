import { useMemo } from "react";
import { CornerLIcon, TableIcon } from "../../Assets/assets";
import footerImage from "../../Assets/TableFooter.svg";
import BarChart from "../../Components/Graphs/BarChart";
import { DoughnutChart } from "../../Components/Graphs/DoughnutChart";
import { isValidArray, isValidObject } from "../../Services/validators";

export default function ComplaintSummary(props) {
  function generateDoughnutDataWithSpacing(data, minPadding = 5) {
    const totalData = data.reduce((sum, value) => sum + value, 0);
    const processedData = [];

    data.forEach((value, index) => {
      processedData.push(value);

      const padding = Math.round(
        Math.max(minPadding, (value / totalData) * minPadding * 2)
      );

      if (index < data.length) {
        processedData.push(padding);
      }
    });

    return processedData;
  }

  const getStatusChartData = (count) => {
    return {
      labels: ["CLOSED", "UNDER REVIEW", "OPEN"],
      datasets: [
        {
          label: "Complaint status",
          data: generateDoughnutDataWithSpacing(
            [count?.closed || 0, count?.review || 0, count?.open || 0],
            (count?.closed + count?.review + count?.open) / 30
          ),
          backgroundColor: [
            count?.closed > 0 ? "rgba(76, 100, 255, 0.5)" : "#c2c2c2",
            "transparent",
            count?.review > 0 ? "rgba(217, 217, 217, 0.5)" : "#c2c2c2",
            "transparent",
            count?.open > 0 ? "rgba(255, 108, 108, 0.5)" : "#c2c2c2",
            "transparent"
          ],
          borderColor: [
            count?.closed > 0 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
            "transparent",
            count?.review > 0 ? "rgba(217, 217, 217, 1)" : "#c2c2c2",
            "transparent",
            count?.open > 0 ? "rgba(255, 108, 108, 1)" : "#c2c2c2",
            "transparent"
          ],
          borderWidth: 2
        }
      ],
      defaultValue: `TOTAL: ${count?.closed +
        count?.review +
        count?.open} COMPLAINTS`
    };
  };

  const getZoneChartData = (count) => {
    if (!isValidObject(count)) {
      return;
    }

    const backgroundColor = [];
    const borderColor = [];

    Object.values(count).forEach((value, index) => {
      const color = generateColorMap()[index] || generateColorMap().default;
      backgroundColor.push(value > 0 ? color.bg : "#c2c2c2");
      borderColor.push(value > 0 ? color.border : "#c2c2c2");
      backgroundColor.push("transparent");
      borderColor.push("transparent");
    });

    return {
      labels: Object.keys(count),
      datasets: [
        {
          label: "Complaint Zones",
          data: generateDoughnutDataWithSpacing(
            Object.values(count),
            Object.values(count).reduce((sum, value) => sum + value, 0) / 30
          ),
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 2
        }
      ],
      defaultValue: `TOTAL: ${Object.values(count).reduce(
        (sum, value) => sum + value,
        0
      )} COMPLAINTS`
    };
  };

  const getCategoryChartData = (count) => {
    if (!isValidObject(count)) {
      return;
    }

    const backgroundColor = [];
    const borderColor = [];

    Object.values(count).forEach((value, index) => {
      const color = generateColorMap()[index] || generateColorMap().default;
      backgroundColor.push(value > 0 ? color.bg : "#c2c2c2");
      borderColor.push(value > 0 ? color.border : "#c2c2c2");
      backgroundColor.push("transparent");
      borderColor.push("transparent");
    });

    return {
      labels: Object.keys(count),
      datasets: [
        {
          label: "Complaint Categories",
          data: generateDoughnutDataWithSpacing(
            Object.values(count),
            Object.values(count).reduce((sum, value) => sum + value, 0) / 30
          ),
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 2
        }
      ],
      defaultValue: `TOTAL: ${Object.values(count).reduce(
        (sum, value) => sum + value,
        0
      )} COMPLAINTS`
    };
  };

  function generateColorMap() {
    const colorMap = {
      default: {
        bg: "rgba(200, 200, 200, 0.5)",
        border: "rgba(200, 200, 200, 1)"
      }
    };

    colors.forEach((color, index) => {
      const borderColor = color.replace(/0\.5\)$/, "1)");
      colorMap[index] = {
        bg: color,
        border: borderColor
      };
    });

    return colorMap;
  }
  const generateLegends = (count, unit) => {
    if (!isValidObject(count)) {
      return;
    }
    return Object.entries(count)
      ?.map(([key, value], index) => {
        const color = generateColorMap()[index] || generateColorMap().default;
        return {
          key: key,
          title: unit ? `${unit} : ${key}` : key,
          value: value?.toString()?.padStart(2, "0"),
          backgroundColor: color.bg,
          border: `solid 1px ${color.border}`
        };
      })
      .sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
  };

  const getRaisedChartData = (count) => {
    return {
      labels: ["INTERNAL", "CUSTOMER SUPPORT", "PUBLIC"],
      datasets: [
        {
          label: "Complaint issued by status",
          data: generateDoughnutDataWithSpacing(
            [
              count?.internal || 0,
              count?.customerSupport || 0,
              count?.public || 0
            ],
            (count?.internal + count?.customerSupport + count?.public) / 30
          ),
          backgroundColor: [
            count?.internal > 0 ? "rgba(76, 100, 255, 0.5)" : "#c2c2c2",
            "transparent",
            count?.customerSupport > 0 ? "rgba(217, 217, 217, 0.5)" : "#c2c2c2",
            "transparent",
            count?.public > 0 ? "rgba(255, 108, 108, 0.5)" : "#c2c2c2",
            "transparent"
          ],
          borderColor: [
            count?.internal > 0 ? "rgba(76, 100, 255, 1)" : "#c2c2c2",
            "transparent",
            count?.customerSupport > 0 ? "rgba(217, 217, 217, 1)" : "#c2c2c2",
            "transparent",
            count?.public > 0 ? "rgba(255, 108, 108, 1)" : "#c2c2c2",
            "transparent"
          ],
          borderWidth: 2
        }
      ],
      defaultValue: `TOTAL: ${count?.internal +
        count?.customerSupport +
        count?.public} COMPLAINTS`
    };
  };

  const getMonthlyComparisonBarChartData = (count) => {
    return {
      labels: count.map((data) => data.month),
      datasets: [
        {
          label: "OPEN",
          backgroundColor: "rgba(255, 108, 108, 0.5)",
          borderColor: "rgba(255, 108, 108, 1)",
          borderWidth: 1,
          data: count.map((data) => data.count.open),
          barThickness: 22
        },
        {
          label: "SPACE",
          backgroundColor: "transparent",
          borderColor: "transparent",
          borderWidth: 1,
          data: Array(new Date().getMonth() + 1).fill(8),
          barThickness: 22
        },
        {
          label: "CLOSED",
          backgroundColor: "rgba(76, 100, 255, 0.5)",
          borderColor: "rgba(76, 100, 255, 1)",
          borderWidth: 1,
          data: count.map((data) => data.count.closed),
          barThickness: 22
        }
      ],
      defaultValue: `AVERAGE: ${(
        count
          .map((data) => data.count.total)
          .reduce((sum, value) => sum + value, 0) / count.length
      ).toFixed(0)} COMPLAINTS`
    };
  };

  const getLongestDurationBarChartData = (complaints) => {
    return {
      labels: complaints.map((data) => data.documentId),
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderColor: "rgba(217, 217, 217, 1)",
          borderWidth: 1,
          data: complaints.map((data) => data.duration.hours),
          barThickness: 22
        }
      ],
      defaultValue: `AVERAGE: ${(
        complaints
          .map((data) => data.duration.hours)
          .reduce((sum, value) => sum + value, 0) / complaints.length ||
        0 / 3600000
      ).toFixed(2) || 0} HR (s)`
    };
  };

  const getClosedDurationBarChartData = (count) => {
    return {
      labels: ["<1 DAY", "1-3 DAYS", "3-7 DAYS", ">7 DAYS"],
      datasets: [
        {
          label: "Dataset 1",
          backgroundColor: "rgba(217, 217, 217, 0.5)",
          borderColor: "rgba(217, 217, 217, 1)",
          borderWidth: 1,
          // eslint-disable-next-line array-callback-return
          data: Object.keys(count).map((key) => {
            if (key !== "average") {
              return count[key];
            }
          }),
          barThickness: 22
        }
      ],
      defaultValue: `AVERAGE: ${count?.average} HR (S)`
    };
  };
  const groupedData = props.summaryData?.reduce((acc, _data, i) => {
    if (i % 3 === 0) {
      acc.push(props.summaryData.slice(i, i + 3));
    }
    return acc;
  }, []);

  const getTotalComplaints = useMemo(() => {
    if (isValidArray(props.summaryData)) {
      return Object.values(
        props.summaryData.find(
          (data) => data.title === "COMPLAINTS STATUS DATA"
        ).count
      )
        .reduce((sum, value) => sum + value, 0)
        ?.toString()
        ?.padStart(2, "0");
    }
  }, [props.summaryData]);

  return (
    <div className="remaining-body-height-from-header flex-justify-content-center flex-align-items-center inherit-parent-width overflow-scroll">
      <div
        style={{
          height: "484px",
          width: "100%"
        }}
      >
        <div className="inherit-parent-width inherit-parent-height display-flex flex-justify-content-center flex-align-items-center">
          <div
            style={{
              overflow: "auto",
              scrollBehavior: "smooth",
              scrollSnapType: "y mandatory"
            }}
            className="inherit-parent-width inherit-parent-height hide-scrollbar"
          >
            {groupedData?.map((element, index) => (
              <div
                key={`grouped-${index}`}
                style={{
                  height: "240px",
                  scrollSnapAlign: "start"
                }}
                className="display-flex inherit-parent-width flex-align-items-center flex-justify-content-center"
              >
                {element.map((data, i) => (
                  <div
                    key={i}
                    style={{
                      height: "100%",
                      width: "33%"
                    }}
                  >
                    <div
                      style={{
                        height: "8px"
                      }}
                      className="display-flex flex-justify-content-space-between flex-align-items-center"
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          transform: "rotate(360deg) translate(-2px,-0.5px)",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <CornerLIcon width={8} height={8} />
                      </div>
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          transform: "rotate(90deg)",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <CornerLIcon width={8} height={8} />
                      </div>
                    </div>
                    <div
                      style={{
                        height: "225px",
                        padding: "32px"
                      }}
                      className="flex-align-items-center flex-justify-content-center flex-align-items-center position-relative responsive-graph-section-height"
                    >
                      {data.title === "COMPLAINTS RAISED DATA" && (
                        <DoughnutChart
                          units={"COMPLAINTS"}
                          title={data.title}
                          data={getRaisedChartData(data.count)}
                          legend={
                            <>
                              {[
                                {
                                  title: "INTERNAL",
                                  value: data.count.internal || 0,
                                  backgroundColor: "rgba(76, 100, 255, 0.5)",
                                  border: "solid 1px rgba(76, 100, 255, 1)"
                                },
                                {
                                  title: "PUBLIC (QR)",
                                  value: data.count.public || 0,
                                  backgroundColor: "rgba(255, 108, 108, 0.5)",
                                  border: "solid 1px rgba(255, 108, 108, 1)"
                                },
                                {
                                  title: "SUPPORT",
                                  value: data.count.customerSupport || 0,
                                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                                  border: "solid 1px rgba(217, 217, 217, 1)"
                                }
                              ].map((data) => (
                                <LegendCard data={data} />
                              ))}
                            </>
                          }
                        />
                      )}

                      {data.title === "COMPLAINTS STATUS DATA" && (
                        <DoughnutChart
                          units={"COMPLAINTS"}
                          title={data.title}
                          data={getStatusChartData(data.count)}
                          legend={
                            <>
                              {[
                                {
                                  title: "CLOSED",
                                  value: data.count.closed,
                                  backgroundColor: "rgba(76, 100, 255, 0.5)",
                                  border: "solid 1px rgba(76, 100, 255, 1)"
                                },
                                {
                                  title: "OPEN",
                                  value: data.count.open,
                                  backgroundColor: "rgba(255, 108, 108, 0.5)",
                                  border: "solid 1px rgba(255, 108, 108, 1)"
                                },
                                {
                                  title: "REVIEW",
                                  value: data.count.review,
                                  backgroundColor: "rgba(217, 217, 217, 0.5)",
                                  border: "solid 1px rgba(217, 217, 217, 1)"
                                }
                              ].map((data) => (
                                <LegendCard
                                  fontSize={"font-size-medium"}
                                  data={data}
                                />
                              ))}
                            </>
                          }
                        />
                      )}

                      {data.title === "COMPLAINTS MONTHLY COMPARISON" && (
                        <BarChart
                          title={data.title}
                          data={getMonthlyComparisonBarChartData(data.count)}
                        />
                      )}

                      {data.title === "LONGEST DURATION COMPLAINTS" && (
                        <BarChart
                          units={"HR (s)"}
                          title={data.title}
                          data={getLongestDurationBarChartData(data.complaints)}
                        />
                      )}

                      {data.title === "COMPLAINTS CLOSURE DURATION" && (
                        <BarChart
                          units={"COMPLAINTS (s)"}
                          title={data.title}
                          data={getClosedDurationBarChartData(data.count)}
                        />
                      )}

                      {data.title === "MAX COMPLAINTS RAISED LOCATIONS" && (
                        <div className=" ">
                          <div className="overlay font-size-medium">
                            COMING SOON...
                          </div>
                          <ProgressChart title="POSITIVE PROCEDURES" />
                          <ProgressChart title="POSITIVE PROCEDURES" />
                          <ProgressChart title="POSITIVE PROCEDURES" />
                        </div>
                      )}

                      {data.title === "COMING SOON 1" && (
                        <div className=" ">
                          <div className="overlay font-size-medium">
                            COMING SOON...
                          </div>
                          <ProgressChart title="POSITIVE PROCEDURES" />
                          <ProgressChart title="POSITIVE PROCEDURES" />
                          <ProgressChart title="POSITIVE PROCEDURES" />
                        </div>
                      )}

                      {data.title === "ZONE WISE COMPLAINTS" && (
                        <DoughnutChart
                          units={"COMPLAINTS"}
                          title={data.title}
                          data={getZoneChartData(data.count)}
                          legend={
                            <div
                              style={{
                                height: "150px",
                                width: "100%"
                              }}
                              className="overflow-scroll hide-scrollbar"
                            >
                              {generateLegends(data.count, "ZONE")?.map(
                                (data) => (
                                  <LegendCard data={data} />
                                )
                              )}
                            </div>
                          }
                          onSegmentClick={(value) => {
                            const key = generateLegends(
                              data.count,
                              "ZONE"
                            )?.find(
                              (ele) =>
                                ele.backgroundColor === value.backgroundColor
                            )?.key;
                            if (key) {
                              props.setFilter("zone", parseInt(key));
                            }
                          }}
                        />
                      )}

                      {data.title === "CATEGORY WISE COMPLAINTS" && (
                        <DoughnutChart
                          units={"COMPLAINTS"}
                          title={data.title}
                          data={getCategoryChartData(data.count)}
                          legend={
                            <div
                              style={{
                                height: "150px",
                                width: "100%"
                              }}
                              className="overflow-scroll hide-scrollbar"
                            >
                              {generateLegends(data.count)?.map((data) => (
                                <LegendCard data={data} />
                              ))}
                            </div>
                          }
                        />
                      )}
                    </div>
                    <div
                      style={{
                        height: "8px"
                      }}
                      className="display-flex flex-justify-content-space-between flex-align-items-center"
                    >
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          transform: "rotate(270deg) translate(-2px,-2.6px)",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <CornerLIcon width={8} height={8} />
                      </div>
                      <div
                        style={{
                          width: "8px",
                          height: "8px",
                          transform: "rotate(180deg) translate(0px,-3px)",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <CornerLIcon width={8} height={8} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            minWidth: "700px",
            width: "75%",
            position: "absolute",
            bottom: "60px"
          }}
        >
          <div className="position-relative inherit-parent-width">
            <div className="position-absolute inherit-parent-width display-flex flex-justify-content-space-between">
              <div
                style={{
                  height: "44px"
                }}
                className="padding-left-large font-size-medium display-flex  flex-align-items-center"
              >
                {props.falseData || `TOTAL COMPLAINTS ${getTotalComplaints}`}
              </div>
              <div
                style={{
                  height: "44px"
                }}
                onClick={props.summaryClick}
                className="padding-right-large font-size-medium display-flex flex-align-items-center cursor-pointer"
              >
                <div
                  style={{ height: "12px", width: "12px" }}
                  className="margin-right-default"
                >
                  <TableIcon height="12" width="12" />
                </div>
                <div>TABLE &nbsp; &nbsp; &nbsp; &nbsp; </div>
              </div>
            </div>

            <img
              style={{
                minHeight: "44px",
                height: "auto",
                width: "100%",
                objectFit: "fill"
              }}
              src={footerImage}
              alt="footerImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const ProgressChart = (props) => {
  return (
    <div>
      <div className="padding-bottom-small font-size-small">{props.title}</div>
      <div className=" display-flex">
        <div className="padding-bottom-default margin-right-large">
          <CornerLIcon width={6} height={6} />
        </div>
        {Array.from({ length: 60 }, (_, index) => (
          <div
            key={index}
            style={{
              height: "12px",
              transform: "rotate(45deg)",
              padding: "0.7px"
            }}
            className={`margin-right-smaller margin-top-large margin-bottom-large animated-progress ${
              index <= (40 / 100) * 60
                ? "background-color-light-blue"
                : "background-white"
            }`}
          />
        ))}
        <div
          className="margin-left-large"
          style={{
            transform: "rotate(180deg)"
          }}
        >
          <CornerLIcon width={6} height={6} />
        </div>
      </div>
    </div>
  );
};

const LegendCard = (props) => {
  return (
    <>
      <div className="padding-bottom-default white-space-no-wrap font-size-medium">
        <div className="display-flex">
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: props.data.backgroundColor || "red",
              border: props.data.border || "red",
              marginLeft: "16px",
              marginRight: "16px",
              padding: "6px"
            }}
          />
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "100px"
            }}
            className={props.fontSize || "font-size-small"}
            title={`${props.data.value || 0} - ${props.data.title}`} // Tooltip content
          >
            {props.data.value || 0} - {props.data.title}
          </div>
        </div>
      </div>
    </>
  );
};

const colors = [
  "rgba(76, 100, 255, 0.5)",
  "rgba(217, 217, 217, 0.5)",
  "rgba(255, 108, 108, 0.5)",
  "rgba(105, 155, 91, 0.5)",
  "rgba(222, 74, 155, 0.5)",
  "rgba(107, 168, 154, 0.5)",
  "rgba(183, 199, 144, 0.5)",
  "rgba(196, 119, 255, 0.5)",
  "rgba(255, 107, 107, 0.5)",
  "rgba(255, 105, 64, 0.5)"
];
